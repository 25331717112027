import React from "react";
import { RestaurantList, UsersList, SettingsList, LogsList, ReportList, PoliciesList } from "./AsideComponents";
import { useStateValue } from "../../State/stateProvider";

import "./Aside.scss";

const Aside = () => {
    const [{auth}] = useStateValue();
    const {groups} = auth;

    const hasAnyGroup = (...requiredGroups) => {
        if (Array.isArray(requiredGroups[0])) {
            requiredGroups = requiredGroups[0];
        }

        if (!Array.isArray(requiredGroups)) {
            throw new Error("requiredGroups must be an array");
        }

        return requiredGroups.some(group => groups.includes(group));
    };

    return (
        <aside className="main__aside aside">
            { hasAnyGroup("Unit", "Regional", "Stakeholder", "Franchisee", "Global") && <RestaurantList/> }

            { hasAnyGroup("Regional", "Franchisee", "Stakeholder", "Global") && <UsersList groups={ groups }/> }

            { hasAnyGroup("Global") && <SettingsList groups={ groups }/> }

            { hasAnyGroup("Global", "Regional") && <ReportList groups={ groups }/> }

            { hasAnyGroup("Global") && <LogsList groups={ groups }/> }

            <PoliciesList/>
        </aside>
    );
};


export default Aside;
