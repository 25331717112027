import { ApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export const getLogs = async ({ pageNumber, pageSize, logType, search } = {}) => {
  try {
    const response = await Client.get_Paginated_Logs(pageNumber, pageSize, logType, search);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};