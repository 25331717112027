import React, { useEffect, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "office-ui-fabric-react";

import { useStateValue } from "../State/stateProvider";
import {
  createCompany,
  deleteCompany,
  updateCompany,
  getAllCompanies,
} from "../Services/DictionaryService";
import { getLicenses } from "../Services/LicensesService";
import DictionaryList from "./DictionaryList";

const CompaniesContainer = () => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const { companies, loadingDictionares, structuresDictionary } = management;
  const [calculations, setCalculations] = useState([]);

  const refreshCompanies = useCallback(async () => {
    const dictionaryResponse = await getAllCompanies();

    if (dictionaryResponse.data) {
      const newCompanies = dictionaryResponse.data;

      if (newCompanies) {
        dispatch({
          type: "changeCompanies",
          newCompanies,
        });
      }
    }
  }, [dispatch]);

  const getCalculations = useCallback(async () => {
    const results = await getLicenses();
    setCalculations(results.data);
  }, [getLicenses]);

  useEffect(() => {
    refreshCompanies();
  }, [refreshCompanies]);

  useEffect(()=>{
    getCalculations();
  }, [getCalculations])

  const handleDelete = async (id) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await deleteCompany(id);

      await refreshCompanies();

      if (success) {
        toast.success(labels.get('company_successfully_deleted'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleEdit = async (id, company) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await updateCompany({ ...company, id });

      await refreshCompanies();

      if (success) {
        toast.success(labels.get('company_successfully_updated'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleAdd = async (company) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await createCompany(company);

      await refreshCompanies();

      if (success) {
        toast.success(labels.get('company_successfully_added'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  return loadingDictionares ? (
    <div className="center">
      <Spinner className="spinner" label={`${labels.get('processing')}...`} />
    </div>
  ) : (
    <div style={{display: 'flex'}}>
      <div>
        <DictionaryList
          dictionary={companies}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onAdd={handleAdd}
          dictionaryName="Company"
          withCodeColumn
          withLicenses
          withStructureId
          withUsedLicenses
          withBrand
          withCountry
          codeType="number"
          structures={structuresDictionary}
        />
      </div>
      <div style={{marginTop: 42, marginLeft: 40}}>
        <li style={{listStyle: 'none'}}>
          <ul><strong>{labels.get('total_licences_pool')}:</strong> {calculations.totalLicences}</ul>
          <ul><strong>{labels.get('licenses_allocated')}:</strong> {calculations.allocatedLicences}</ul>
          <ul><strong>{labels.get('licenses_used')}:</strong> {calculations.usedLicences}</ul>
          <ul><strong>{labels.get('licences_left')}:</strong> {calculations.leftLicences}</ul>
        </li>
      </div>
    </div>
  );
};

export default CompaniesContainer;
