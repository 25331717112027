import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Spinner, createTheme, loadTheme } from "office-ui-fabric-react";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import { EventType } from "@azure/msal-browser";
import * as EventEmitter2 from 'eventemitter2';

import { Header, Main } from "./components";
import { getUserDetails } from "./GraphService";
import { useStateValue } from "./components/State/stateProvider";
import { getAllStructures } from "./components/Services/StructureService";
import {
  getStructuresUnits,
  getAllUnits,
} from "./components/Services/UnitService";
import {
  getEmployeeDictionary,
  getUnitFormDictionary,
} from "./components/Services/DictionaryService";
import { getAllRegions } from "./components/Services/RegionService";
import { ApiClient } from "./components/Services/ApiService";
import { getAllLabels, Labels } from "./components/Services/LabelsService";
import { userAgentApplication } from "./components/State/authReducer";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

export const emitter = new EventEmitter2();

const appTheme = createTheme({
  palette: {
    themePrimary: "#f5970f",
    themeLighterAlt: "#fffbf5",
    themeLighter: "#fdedd7",
    themeLight: "#fcdeb5",
    themeTertiary: "#f9be6d",
    themeSecondary: "#f6a12b",
    themeDarkAlt: "#dc860d",
    themeDark: "#ba710b",
    themeDarker: "#895308",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

loadTheme(appTheme);

// fix date on frontend
Date.prototype.toJSON = function () {
  const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
  this.setHours(hoursDiff);
  return this.toISOString();
};

const loginRequest = {
  scopes: ["user.read", process.env.REACT_APP_GROUP_SCOPE],
  prompt: "select_account",
};

const App = () => {
  const [{ allLabels: { labels } }, dispatch] = useStateValue();
  const history = useHistory();

  const enablePortal = !!ApiClient;
  const tokenRefreshTimerRef = useRef(null);
  const refreshCountRef = useRef(0);

  const printError = (err) => {
    let newError = {};

    if (typeof err === "string") {
      var errParts = err.split("|");
      newError =
          errParts.length > 1
              ? { message: errParts[1], debug: errParts[0] }
              : { message: err };
    } else {
      newError = {
        message: err.message,
        debug: JSON.stringify(err),
      };
    }

    dispatch({
      type: "changeAuthenticated",
      newAuthenticated: false,
      newUser: {},
      newGroups: [],
      newError,
    });
  };

  const loadAllLabels = async () => {
    const labelsResponse = await getAllLabels();

    if(labelsResponse?.data) {
      const newLabels = new Map(labelsResponse.data.map(
          label => [label.key, label.value]
      ));

      emitter.on('toast_error', (label) => {
        toast.error(newLabels.get(label));
      });

      dispatch({
        type: "changeLabels",
        newLabels: new Labels(newLabels),
      });
    }
  };

  useEffect(() => {
    loadAllLabels().catch(e => console.error(e));
  }, [ApiClient]);

  useEffect(() => {
    const loginRedirect = async () => {
      try {
        history.replace("/");

        userAgentApplication.loginRedirect(loginRequest);
      } catch (error) {
        console.error(error);
        printError(error);
      }
    };

    const isTokenExpired = (accessToken) => {
      const now = new Date();
      return accessToken.expiresOn <= now;
    };

    const startTokenRefresh = (accessToken) => {
      const tokenExpirationTime = accessToken.expiresOn.getTime();
      const currentTime = new Date().getTime();
      const refreshTime = tokenExpirationTime - 30 * 60 * 1000 - currentTime;

      if (refreshCountRef.current >= 10) {
        clearTimeout(tokenRefreshTimerRef.current);
        return;
      }

      tokenRefreshTimerRef.current = setTimeout(async () => {
        try {
          const refreshedToken = await userAgentApplication.acquireTokenSilent({
            scopes: ["user.read"],
          });

          if (refreshedToken && !isTokenExpired(refreshedToken)) {
            console.log("Token refreshed");
          }
        } catch (error) {
          console.error("Token refresh failed", error);
        } finally {
          refreshCountRef.current += 1;
          startTokenRefresh(accessToken);
        }
      }, refreshTime);
    };

    const getUserProfile = async () => {
      try {
        const accessToken = await userAgentApplication.acquireTokenSilent({
          scopes: ["user.read"],
        });

        if (!accessToken || isTokenExpired(accessToken)) {
          await userAgentApplication.loginRedirect(loginRequest);
        } else {
          startTokenRefresh(accessToken);
        }

        const groupAccessToken = await userAgentApplication.acquireTokenSilent({
          scopes: [process.env.REACT_APP_GROUP_SCOPE],
        });

        const decodedGroupAccessToken = jwt_decode(groupAccessToken.accessToken);
        const newGroups = new Set();

        if (decodedGroupAccessToken.groups.includes(process.env.REACT_APP_GROUP_GLOBAL)) {
          newGroups.add("Global");
        }

        if (process.env.REACT_APP_GROUP_LOCAL && decodedGroupAccessToken.groups.includes(process.env.REACT_APP_GROUP_LOCAL)) {
          newGroups.add("Franchisee");
        }

        if (decodedGroupAccessToken.groups.includes(process.env.REACT_APP_GROUP_REGIONAL)) {
          newGroups.add("Regional");
        }

        if (process.env.REACT_APP_GROUP_STAKEHOLDER && decodedGroupAccessToken.groups.includes(process.env.REACT_APP_GROUP_STAKEHOLDER)) {
          newGroups.add("Stakeholder");
        }

        if (decodedGroupAccessToken.groups.includes(process.env.REACT_APP_GROUP_UNIT)) {
          newGroups.add("Unit");
        }

        const newUser = await getUserDetails(accessToken.accessToken);

        dispatch({
          type: "changeAuthenticated",
          newAuthenticated: true,
          newUser: {
            mail: newUser.mail,
            displayName: newUser.displayName,
          },
          newGroups: Array.from(newGroups),
          newToken: accessToken,
          newGroupAccessToken: groupAccessToken.accessToken,
          newError: null,
        });

        if (
            newGroups.has("Unit") ||
            newGroups.has("Regional") ||
            newGroups.has("Franchisee") ||
            newGroups.has("Stakeholder") ||
            newGroups.has("Global")
        ) {
          const structuresResponse = await getAllStructures();
          const structuresUnitsResponse = await getStructuresUnits();
          const unitsResponse = await getAllUnits();
          const regionsResponse = await getAllRegions();

          if (
              structuresResponse?.data &&
              structuresUnitsResponse?.data &&
              unitsResponse?.data &&
              regionsResponse?.data
          ) {
            dispatch({
              type: "changeStructuresRegionsUnits",
              newStructures: structuresResponse.data,
              newStructuresUnits: structuresUnitsResponse.data,
              newUnits: unitsResponse.data,
              newRegions: regionsResponse.data,
            });
          }
          dispatch({
            type: "changeLoadingUnits",
            loading: false,
          });

          const employeeDictionary = await getEmployeeDictionary();
          const unitFormDictionary = await getUnitFormDictionary();

          if (employeeDictionary.data) {
            const newJobTitles = employeeDictionary.data.employeeJobTitle;
            const newEmployeeTypes = employeeDictionary.data.employeeType;

            dispatch({
              type: "changeJobTitles",
              newJobTitles,
            });

            dispatch({
              type: "changeEmployeeTypes",
              newEmployeeTypes,
            });
          }

          if (unitFormDictionary.data) {
            const newDictionaries = unitFormDictionary.data;

            dispatch({
              type: "changeDictionaries",
              newDictionaries,
            });
          }

          dispatch({
            type: "changeLoadingDictionaries",
            loading: false,
          });
        }
      } catch (err) {
        console.error(err);
        printError(err);
        loginRedirect();
      }
    };

    userAgentApplication.addEventCallback(
        (event) => {
          if (
              event.eventType === EventType.LOGIN_SUCCESS &&
              event.payload.account
          ) {
            const account = event.payload.account;
            userAgentApplication.setActiveAccount(account);
            getUserProfile();
          }
        },
        (error) => {
          console.error("error", error);
        }
    );

    userAgentApplication
        .handleRedirectPromise()
        .then((response) => {
          const account = userAgentApplication.getActiveAccount();

          if (!account) {
            const accounts = userAgentApplication.getAllAccounts();

            if (!accounts?.length) {
              if (response?.account) {
                userAgentApplication.setActiveAccount(response.account);
                getUserProfile();
              } else {
                userAgentApplication.loginRedirect(loginRequest);
              }
            } else {
              userAgentApplication.setActiveAccount(accounts[0]);
              getUserProfile();
            }
          } else {
            getUserProfile();
          }
          loadAllLabels();
        })
        .catch((err) => {
          console.error(err);
        });

    return () => {
      if (tokenRefreshTimerRef.current) {
        clearTimeout(tokenRefreshTimerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div>
        {enablePortal ? <Header /> : null}
        {enablePortal ? <Main /> : null}
        {!enablePortal ? (
            <div className="center">
              <Spinner className="spinner" label={'Loading app...'} />
            </div>
        ) : null}
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
        />
      </div>
  );
};

export default App;
