import { ApiClient as Client, handleLogout } from "./ApiService";
import { UnitRequestModel } from "./Api";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export const getAllUnits = async () => {
  try {
    const response = await Client.unitGET();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const postUnit = async (unit) => {
    try {
        const requestModel = mapUnitRequsetModel(unit);
        const response = await Client.unitPOST(requestModel);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getUnit = async (id) => {
  try {
    const response = await Client.get_Unit(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const putUnit = async (id, unit) => {
  try {
    const requestModel = mapUnitRequsetModel(unit);
    const response = await Client.unitPUT(id, requestModel);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteUnit = async (id) => {
  try {
    const response = await Client.unitDELETE(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getStructuresUnits = async () => {
  try {
    const response = await Client.get_Units_Grouped_By_Structure();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getStructureUnits = async (id) => {
  try {
    const response = await Client.get_Units_For_Selected_Structure(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getRegionUnits = async (id) => {
  try {
    const response = await Client.get_Units_For_Selected_Region(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const postUnitToRegion = async (data) => {
  try {
    const response = await Client.assign_Unit_To_Region(data);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const assignUnitToMailbox = async (id) => {
  try {
    const response = await Client.assign_Unit_To_Shared_Mailbox({ id });

    toast.success("Restaurant has been assigned to shared mailbox!");
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

function mapUnitRequsetModel(model) {
    const requestModel = new UnitRequestModel();
    requestModel.unitName = model.unitName;
    requestModel.countryId = model.countryId;
    requestModel.brandId = model.brandId;
    requestModel.costCenterId = model.costCenterId;
    requestModel.companyId = model.companyId;
    requestModel.restaurantAssetTypeId = model.restaurantAssetTypeId;
    requestModel.restaurantConceptId = model.restaurantConceptId;
    requestModel.franchiseBusinessCoachId = model.franchiseBusinessCoachId;
    requestModel.franchiseBusinessCoachId = model.franchiseBusinessCoachId;
    requestModel.street = model.street;
    requestModel.city = model.city;
    requestModel.zipCode = model.zipCode;
    requestModel.regionId = model.regionId;
    requestModel.phoneNumber = model.phoneNumber;
    requestModel.openingDate = model.openingDate;
    requestModel.closeTime = model.closeTime;
    requestModel.comments = model.comments;
    requestModel.franchiseePhoneNumber = model.franchiseePhoneNumber;
    requestModel.franchiseeEmail = model.franchiseeEmail;
    requestModel.unitManagerId = model.unitManagerId;

    return requestModel;
}
