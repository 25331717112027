import { PublicClientApplication } from "@azure/msal-browser";

export const userAgentApplication = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    validateAuthority: true,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
});

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'changeAuthenticated':
      return {
        ...state,
        accessToken: action.newAccessToken,
        authenticated: action.newAuthenticated,
        error: action.newError,
        groupAccessToken: action.newGroupAccessToken,
        groups: action.newGroups,
        user: action.newUser,
      };
    default:
      return state;
  }
};

export const authReducerInitialState = {
  accessToken: null,
  authenticated: false,
  error: null,
  groups: [],
  groupAccessToken: null,
  user: {}
};
