import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Spinner } from "office-ui-fabric-react";

import { useStateValue } from "../State/stateProvider";
import {
  createFranchiseBusinessCoach,
  updateFranchiseBusinessCoach,
  deleteFranchiseBusinessCoach,
  getAllFranchiseBusinessCoaches,
} from "../Services/DictionaryService";
import DictionaryList from "./DictionaryList";

const FranchiseBusinessCoachesContainer = () => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const { franchiseBusinessCoaches, loadingDictionares} = management;

  const refreshFranchiseBusinessCoaches = useCallback(async () => {
    const dictionaryResponse = await getAllFranchiseBusinessCoaches();

    if (dictionaryResponse.data) {
      const newFranchiseBusinessCoaches = dictionaryResponse.data;

      dispatch({
        type: "changeFranchiseBusinessCoaches",
        newFranchiseBusinessCoaches,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    refreshFranchiseBusinessCoaches();
  }, [refreshFranchiseBusinessCoaches]);

  const handleDelete = async (key) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await deleteFranchiseBusinessCoach(key);

      await refreshFranchiseBusinessCoaches();

      if (success) {
        toast.success(labels.get('franchise_business_coach_has_been_successfully_deleted'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleEdit = async (key, text) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await updateFranchiseBusinessCoach(key, text);

      await refreshFranchiseBusinessCoaches();
      if (success) {
        toast.success(labels.get('franchise_business_coach_has_been_successfully_updated'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleAdd = async (text) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await createFranchiseBusinessCoach(text);

      await refreshFranchiseBusinessCoaches();
      if (success) {
        toast.success(labels.get('franchise_business_coach_has_been_successfully_added'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  return loadingDictionares ? (
    <div className="center">
      <Spinner className="spinner" label={`${labels.get('processing')}...`} />
    </div>
  ) : (
    <DictionaryList
      dictionary={franchiseBusinessCoaches}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onAdd={handleAdd}
      dictionaryName="Franchise Business Coach"
      withBrand
      withCountry
    />
  );
};

export default FranchiseBusinessCoachesContainer;
