import React, { useState, useEffect } from "react";
import { Spinner } from "office-ui-fabric-react";
import { Icon } from "office-ui-fabric-react";
import { getAllReports, getPeopleReport, getRestaurantsReport } from "../Services/ReportsService";
import { useStateValue } from "../State/stateProvider";
import "./Reports.scss";

const REPORTS = {
  PEOPLE_REPORT: 'PeopleReport',
  RESTAURANTS_REPORT: 'RestaurantsReport',
}

const ReportsContainer = () => {
  const [{ allLabels: { labels } }] = useStateValue();
  const [reports, setReports] = useState([]);
  const [isReportLoading, setIsReportLoading] = useState(false);

  useEffect(() => {
    getAllReports().then(data => setReports(data || [])).catch(e => console.error(e));
  }, []);

  const downloadReport = async (name) => {
    setIsReportLoading(true);

    let response = null;

    switch(name) {
      case REPORTS.PEOPLE_REPORT:
        response = await getPeopleReport();
        break;
      case REPORTS.RESTAURANTS_REPORT:
        response = await getRestaurantsReport();
        break;
    }

    if(response) {
      const objectURL = window.URL.createObjectURL(response.data);
      const fileLink = document.createElement('a');

      fileLink.href = objectURL;
      fileLink.setAttribute('download', name);

      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    }

    setIsReportLoading(false);
  }

  const renderReportItem = ({name}) => {
    return (
      <li className="report-item" onClick={() => downloadReport(name)}>
          <Icon iconName={'ExcelDocument'} className='report-icon' />
          {name}
      </li>
    );
  }

  const renderReportsList = () => {
    return (
      <>
        <h1>{labels.get('reports')}</h1>
        <ul className="reports-list">
          {reports.map(report => (
              <React.Fragment key={report.name}>
                {renderReportItem(report)}
              </React.Fragment>
          ))}
        </ul>
      </>
    )
  }

  const showSpinner = () => {
    return (
      <div className='center spinner-report'>
          <Spinner className="spinner" label={`${labels.get('downloading_report')}...`} />
        </div>
    )
  }

  return (
    <>
      { isReportLoading && showSpinner() }
      { renderReportsList() }
    </>
  );
};

export default ReportsContainer;
