import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ContextualMenu } from "office-ui-fabric-react";
import { useStateValue } from "../../State/stateProvider";
import { userAgentApplication } from '../../State/authReducer';
import { getAllLabels, Labels } from "../../Services/LabelsService";
import { ApiClient } from "../../Services/ApiService";

const User = () => {
    const linkRef = useRef(null);
    const [{auth, allLabels: {labels}}, dispatch] = useStateValue();
    const {user} = auth;
    const history = useHistory();

    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [availableLanguages, setAvailableLanguages] = useState(['en']);

    const countries = [
        {countryCode: "AE", languageCode: "EN"},
        {countryCode: "BE", languageCode: "BE"},
        {countryCode: "CZ", languageCode: "CZ"},
        {countryCode: "ES", languageCode: "ES"},
        {countryCode: "FR", languageCode: "FR"},
        {countryCode: "GB", languageCode: "GB"},
        {countryCode: "HU", languageCode: "HU"},
        {countryCode: "PL", languageCode: "PL"},
        {countryCode: "SA", languageCode: "EN"},
        {countryCode: "SK", languageCode: "EN"},
    ];

    const extractUniqueLanguages = (countries) => {
        return [...new Set(countries.map(country => country.languageCode))];
    };

    const onShowContextualMenu = () => setShowContextualMenu(true);
    const onHideContextualMenu = () => setShowContextualMenu(false);

    const logout = () => {
        userAgentApplication.logoutRedirect();
    };

    const changeAccount = () => {
        history.replace("/");
        localStorage.clear();
        sessionStorage.clear();
        const cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        const loginRequest = {
            scopes: ["user.read", process.env.REACT_APP_GROUP_SCOPE],
            prompt: "select_account"
        };

        userAgentApplication.loginRedirect(loginRequest);
    };

    const menuItems = [
        {
            key: "logout",
            text: labels.get('logout'),
            onClick: () => logout()
        },
        {
            key: "changeAccount",
            text: labels.get('change_account'),
            onClick: () => changeAccount()
        }
    ];

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        loadAllLabels(newLanguage).catch(e => console.error(e));
    };

    useEffect(() => {
        loadAllLabels(selectedLanguage).catch(e => console.error(e));
    }, [selectedLanguage]);


    const loadAllLabels = async (language) => {
        const labelsResponse = await getAllLabels(language);

        if(labelsResponse?.data) {
            const newLabels = new Map(labelsResponse.data.map(
                label => [label.key, label.value]
            ));

            dispatch({
                type: "changeLabels",
                newLabels: new Labels(newLabels),
            });
        }
    };

    useEffect(() => {
        setAvailableLanguages(extractUniqueLanguages(countries));
    }, []);

    return (
        <div className="header__user-container">
            <span>{ labels.get('welcome') }: { user.displayName }!</span>
            <select className="lang-wrapper" onChange={ handleLanguageChange }>
                { availableLanguages.map(language => (
                    <option key={ language } value={ language }>
                        { language }
                    </option>
                )) }
            </select>
            <button
                className="header__user active"
                ref={ linkRef }
                onClick={ onShowContextualMenu }
            >
                <ContextualMenu
                    items={ menuItems }
                    hidden={ !showContextualMenu }
                    target={ linkRef }
                    onItemClick={ onHideContextualMenu }
                    onDismiss={ onHideContextualMenu }
                />
            </button>
        </div>
    );
};

export default User;
