import React from 'react';
import {
  FontIcon
} from 'office-ui-fabric-react';
import {
  Link
} from "react-router-dom";

const Logo = () => {

  return (
    <div className='header__logo'>
      <FontIcon 
        iconName='WaffleOffice365'
        className='waffle'
      />
      <Link to='/'>
        AmRest
      </Link>
    </div>
  )
}

export default Logo;