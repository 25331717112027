import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const TranslationService = {
    downloadXlsx: async (languageCode) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/Storage/download-xlsx`, {
                params: { languageCode },
            });

            const { fileContents, contentType } = response.data.data;

            const byteCharacters = atob(fileContents);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: contentType });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${languageCode}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    },

    uploadXlsx: async (file, languageCode) => {
        if (!file || !languageCode) {
            console.error('missing parameters');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
       formData.append('languageCode', languageCode);

        try {
            const response = await axios.post(`${API_BASE_URL}/api/Storage/upload-xlsx`, formData, {
                params: { languageCode },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error uploading the file:', error);
            throw error;
        }
    },
};

export default TranslationService;
