import React, { useState, useEffect } from 'react'
import LanguageSelect from "./LanguageSelect";
import FileUpload from "./FileUpload";
import FileDownload from "./FileDownload";
import "./TranslationsContainer.css";
import TranslationsService from "../Services/TranslationsService";

const TranslationsContainer = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    const handleDownload = async (selectedLanguage) => {
        try {
            await TranslationsService.downloadXlsx(selectedLanguage)
        } catch (error) {
            console.error(error);
        }
    };


    const handleFileChange = async (file) => {
        setSelectedFile(file);
    };

    return (
        <div>
            <p>Translations</p>
            <LanguageSelect
                onSelectLanguage={ handleLanguageChange }
            />
            { selectedLanguage && (
                <div className="file-actions">
                    <FileDownload
                        selectedLanguage={ selectedLanguage }
                        onDownload={ handleDownload }
                    />
                    <FileUpload
                        selectedLanguage={ selectedLanguage }
                        selectedFile={ selectedFile }
                        onFileChange={ handleFileChange }
                    />
                </div>
            ) }
        </div>
    );
}

export default TranslationsContainer;
