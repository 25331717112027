import React from 'react';
import { Section } from './MainComponents';
import Aside from './Aside/Aside';

import './Main.scss';

const Main = () => {

  return (
    <main className='main'>
      <Aside />
      <Section />
    </main> 
  )
}

export default Main;