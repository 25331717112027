import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from "react";
import {
  Panel,
  PanelType,
  DefaultButton,
  PrimaryButton,
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react";
import { useStateValue } from "../../../State/stateProvider";
import { getAllStructures } from "../../../Services/StructureService";
import { getStructuresUnits, getAllUnits } from "../../../Services/UnitService";
import {
  getAllRegions,
  putRegion,
  deleteRegion,
} from "../../../Services/RegionService";
import Form from "../../../Common/Form";

const EditRegion = forwardRef((props, ref) => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const { regionsDictionary, structuresDictionary, brands, countries } = management;

  const [item, setItem] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const openPanel = () => setIsEditing(true);
  const dismissPanel = () => setIsEditing(false);

  const changeItem = (value, field) => {
    const newItem = {};

    switch (field) {
      case "structureId":
        if (value?.id) {
          newItem.structureId = value.id;
          newItem.structure = value;
          if (newItem.structureId !== value.id) {
            newItem.parentId = null;
            newItem.parent = null;
          }
        } else {
          newItem.structureId = null;
          newItem.structure = null;
          newItem.parentId = null;
          newItem.parent = null;
        }
        break;
      case "parentId":
        if (value?.id) {
          newItem.parentId = value.id;
          newItem.parent = value;
        } else {
          newItem.parentId = null;
          newItem.parent = null;
        }
        break;
      default:
        break;
    }

    setItem((oldItem) => ({ ...oldItem, [field]: value, ...newItem }));
  };

  const confirmChanges = async () => {

    const request = await putRegion({
      id: item.id,
      name: item.name,
      parentId: item.parentId,
      brandId: item.brandId,
      countryId: item.countryId
    });

    if (request?.data?.id) {
      dispatch({
        type: 'changeLoadingUnits',
        loading: true
      })
      const newStructures = await getAllStructures();
      const newStructuresUnits = await getStructuresUnits();
      const newUnits = await getAllUnits();
      const newRegions = await getAllRegions();

      if (
          newStructures?.data &&
          newStructuresUnits?.data &&
          newUnits?.data &&
          newRegions?.data
      ) {
        dispatch({
          type: "changeStructuresRegionsUnits",
          newStructures: newStructures.data,
          newStructuresUnits: newStructuresUnits.data,
          newUnits: newUnits.data,
          newRegions: newRegions.data,
        });
      }

      dispatch({
        type: 'changeLoadingUnits',
        loading: false
      })

      setItem({});
      setIsEditing(false);
    }
  };

  useImperativeHandle(ref, () => ({
    editEntity(entity) {
      setItem({
        id: entity.id,
        name: entity.name,
        parentId: entity.parentId,
        structureId: entity.structureId,
        brandId: entity.brandId,
        countryId: entity.countryId,
      });
      openPanel();
    },
  }));

  const fields = useMemo(
    () => [
      {
        type: "column",
        key: "1",
        fields: [
          {
            type: "dropdown",
            label: labels.get('structure'),
            name: "structureId",
            disabled: true,
            options: structuresDictionary
          },
          {
            type: "dropdown",
            label: labels.get('parent_region'),
            name: "parentId",
            options: regionsDictionary.filter(
              (region) => region.structureId === item.structureId
            )
          },
          {
            type: "dropdown",
            label: labels.get('brand'),
            name: "brandId",
            options: brands,
            disabled: true
          },
          {
            type: "dropdown",
            label: labels.get('country'),
            name: "countryId",
            options: countries,
            disabled: true
          },
          {
            type: "text",
            label: labels.get('region_name'),
            name: "name",

          },
        ],
      },
    ],
    [structuresDictionary, regionsDictionary, item.structureId]
  );

  const [dialog, setDialog] = useState(false);

  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => setDialog(false);

  const confirmDialog = useCallback(async () => {
    const response = await deleteRegion(item.id);
    setDialog(false);

    if (response) {
      setIsEditing(false);
      setItem({});
      dispatch({
        type: 'changeLoadingUnits',
        loading: true
      })

      const newStructures = await getAllStructures();
      const newStructuresUnits = await getStructuresUnits();
      const newUnits = await getAllUnits();
      const newRegions = await getAllRegions();

      if (
        newStructures?.data &&
        newStructuresUnits?.data &&
        newUnits?.data &&
        newRegions?.data
      ) {
        dispatch({
          type: "changeStructuresRegionsUnits",
          newStructures: newStructures.data,
          newStructuresUnits: newStructuresUnits.data,
          newUnits: newUnits.data,
          newRegions: newRegions.data,
        });
      }

      dispatch({
        type: 'changeLoadingUnits',
        loading: false
      })
    }
  }, [item, dispatch]);

  const onRenderFooterContent = useCallback(
    () => (
      <div style={{ display: "flex", gap: "12px" }}>
        <PrimaryButton onClick={confirmChanges}>{labels.get('save_changes')}</PrimaryButton>
        <DefaultButton onClick={openDialog}>{labels.get('delete_region')}</DefaultButton>
        <Dialog
          hidden={!dialog}
          onDismiss={closeDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: labels.get('delete_region'),
          }}
        >
          <p>{labels.get('are_you_sure_you_want_to_delete')} {item.name}?</p>
          <DialogFooter>
            <PrimaryButton onClick={confirmDialog} text={labels.get('delete')} />
            <DefaultButton onClick={closeDialog} text={labels.get('cancel')} />
          </DialogFooter>
        </Dialog>
      </div>
    ),
    [confirmChanges, confirmDialog, dialog, item.name]
  );

  return (
    <Panel
      headerText={labels.get('edit_region')}
      type={PanelType.custom}
      customWidth="28rem"
      isLightDismiss
      isOpen={isEditing}
      onDismiss={dismissPanel}
      closeButtonAriaLabel={labels.get('close')}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      <Form item={item} changeItem={changeItem} fields={fields} />
    </Panel>
  );
});

export default EditRegion;
