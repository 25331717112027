import React from "react";
import {
  Separator,
  TextField,
  DatePicker,
} from "office-ui-fabric-react";
import { useStateValue } from "../../components/State/stateProvider";

import Autocomplete from './Autocomplete';

import "./Form.scss";

// eslint-disable-next-line no-useless-escape
const emailTester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

// eslint-disable-next-line no-useless-escape
const phoneTester = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}[-\s\.]?([0-9]{3,6})?/;

const bonAmpetitCardTester = /^([\d]{16})?$/;

const Form = (props) => {
  const { changeItem, item, fields } = props;
  const [{ allLabels: { labels } }] = useStateValue();

  const onChange = (value, field, length = null) => {
    if (length && value.length <= length) {
      changeItem(value, field);
    } else if (!length) {
      changeItem(value, field);
    }
  };

  const textFieldErrorMessage = (item, field) => {
    if (field.name === "bonAmpetitCard") {
      if(item?.[field.name] && !bonAmpetitCardTester.test(item[field.name])) {
        return labels.get('wrong_bonampetit_card_number')
      }
    }
      
    if (field.required && !item?.[field.name] && item?.[field.name] !== undefined) {
      return labels.get('field_required');
    }
    
  }

  const fieldGenerator = (field) => {
    let className = "";

    if (field.fullWidth) {
      className = "form__field--full-width";
    }
    if (field.type === "text") {
      return (
        <TextField
          label={field.label}
          value={item?.[field.name] || ""}
          disabled={field.disabled || false}
          required={field.required || false}
          onChange={(e, value) => onChange(value, field.name, field.length)}
          errorMessage={textFieldErrorMessage(item, field)}
          key={field.name}
          multiline={field.multiline || false}
          className={className}
        />
      );
    } else if (field.type === "email") {
      return (
        <TextField
          label={field.label}
          value={item?.[field.name] || ""}
          disabled={field.disabled || false}
          required={field.required || false}
          onChange={(e, value) => onChange(value, field.name)}
          errorMessage={
            item?.[field.name] && !emailTester.test(item?.[field.name])
              ? labels.get('provide_valid_email')
              : ""
          }
          key={field.name}
          className={className}
        />
      );
    } else if (field.type === "phone") {
      return (
        <TextField
          // mask="999 999 999"
          label={field.label}
          value={item?.[field.name] || ""}
          disabled={field.disabled || false}
          required={field.required || false}
          errorMessage={
            item?.[field.name] && !phoneTester.test(item?.[field.name])
              ? labels.get('provide_valid_phone_number')
              : ""
          }
          onChange={(e, value) => onChange(value, field.name)}
          key={field.name}
          className={className}
        />
      );
    } else if (field.type === "number") {
      return (
        <TextField
          label={field.label}
          type="number"
          value={item?.[field.name] ?? ""}
          disabled={field.disabled || false}
          required={field.required || false}
          min={0}
          onChange={(e, value) => onChange(parseInt(value, 10), field.name)}
          errorMessage={
            field.required &&
            !(item?.[field.name] >= 0) &&
            item?.[field.name] !== undefined
              ? labels.get('field_required')
              : ""
          }
          key={field.name}
          className={className}
        />
      );
    } else if (field.type === "dropdown") {
      return (
        <Autocomplete
          label={field.label}
          options={field.options}
          value={item?.[field.name] || ""}
          disabled={field.disabled || false}
          required={field.required || false}
          onChange={(e, value) => onChange(value, field.name)}
          disablePortal={!field.enablePortal}
          errorMessage={
            field.required &&
            !item?.[field.name] &&
            item?.[field.name] !== undefined
              ? labels.get('field_required')
              : ""
          }
          key={field.name}
          className={className}
          showCode={field.showCode}
        />
      );
    } else if (field.type === "date") {
      return (
        <DatePicker
          showWeekNumbers={true}
          firstWeekOfYear={1}
          showMonthPickerAsOverlay={true}
          label={field.label}
          ariaLabel={field.label}
          value={(item?.[field.name] && new Date(item[field.name])) || ""}
          errorMessage={
            field.required && !item?.[field.name]
              ? labels.get('field_required')
              : ""
          }
          disabled={field.disabled || false}
          isRequired={field.required || false}
          minDate={field.minDate || undefined}
          maxDate={field.maxDate || undefined}
          allowTextInput={true}
          onSelectDate={(value) => onChange(value, field.name)}
          key={field.name}
          className={className}
        />
      );
    } else {
      return null;
    }
  };

  const groupGenerator = (fieldBox) => {
    let className = "form__column";

    if (fieldBox.type === "columns") {
      className = "form__columns";
    }

    return (
      <div key={fieldBox.key}>
        <div className={className}>
          {fieldBox.fields.map((field) => fieldGenerator(field))}
        </div>
        {fieldBox.separator ? <Separator className="separator" /> : null}
      </div>
    );
  };

  return (
    <div className="form">
      {fields.map((fieldBox) => groupGenerator(fieldBox))}
    </div>
  );
};

export default Form;
