import React, { useState } from "react";
import { useHistory } from "react-router";
import { Panel, PanelType, PrimaryButton } from "office-ui-fabric-react";
import { toast } from "react-toastify";

import Button from "./Button";
import Form from "../../../Common/Form";
import { postStructure, getAllStructures } from "../../../Services/StructureService";
import { getStructuresUnits, getAllUnits } from "../../../Services/UnitService";
import { getAllRegions } from "../../../Services/RegionService";
import { useStateValue } from "../../../State/stateProvider";

const AddStructure = () => {
  const history = useHistory();

  const [{allLabels: { labels }}, dispatch] = useStateValue();

  const [newItem, setNewItem] = useState({});

  const openAddPanel = () => setIsCreating(true);
  const dismissAddPanel = () => setIsCreating(false);

  const [isCreating, setIsCreating] = useState(false);

  const confirmChanges = async () => {
    const newStructure = await postStructure({
      code: newItem.code,
      name: newItem.name,
    });

    if (newStructure?.data?.id) {
      toast.success(labels.get('structure_added'));
      dispatch({
        type: 'changeLoadingUnits',
        loading: true
      })
      
      const newStructures = await getAllStructures();
      const newStructuresUnits = await getStructuresUnits();
      const newUnits = await getAllUnits();
      const newRegions = await getAllRegions();

      if (
        newStructures?.data &&
        newStructuresUnits?.data &&
        newUnits?.data &&
        newRegions?.data
      ) {
        dispatch({
          type: "changeStructuresRegionsUnits",
          newStructures: newStructures.data,
          newStructuresUnits: newStructuresUnits.data,
          newUnits: newUnits.data,
          newRegions: newRegions.data,
        });
      }

      dispatch({
        type: 'changeLoadingUnits',
        loading: false
      })

      setNewItem({});
      dismissAddPanel();
      history.push(`/structure/${newStructure?.data?.id}`);
    }
  };

  const changeItem = (value, field) => {
    setNewItem((oldItem) => {
      return {
        ...oldItem,
        [field]: value,
      };
    });
  };

  const onRenderFooterContent = () => (
    <div>
      <PrimaryButton onClick={confirmChanges}>{labels.get('save')}</PrimaryButton>
    </div>
  );

  const fields = [
    {
      type: "column",
      key: "1",
      fields: [
        {
          type: "text",
          label: labels.get('structure_code'),
          name: "code",
        },
        {
          type: "text",
          label: labels.get('structure_name'),
          name: "name",
        },
      ],
    },
  ];

  return (
    <div>
      <Button
        name={labels.get('add_new_structure')}
        icon="Add"
        className="addButton"
        action={openAddPanel}
      />
      <Panel
        headerText={labels.get('add_new_structure')}
        type={PanelType.custom}
        customWidth={"28rem"}
        isLightDismiss
        isOpen={isCreating}
        onDismiss={dismissAddPanel}
        closeButtonAriaLabel={labels.get('close')}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Form item={newItem} changeItem={changeItem} fields={fields} />
      </Panel>
    </div>
  );
};

export default AddStructure;
