import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Panel, PanelType, PrimaryButton } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import Button from "./Button";
import Form from "../../../Common/Form";
import {
  postUnit,
  getStructuresUnits,
  getAllUnits,
} from "../../../Services/UnitService";
import { getRegionEmployees } from "../../../Services/EmployeeService";
import { getAllStructures } from "../../../Services/StructureService";
import { getAllRegions } from "../../../Services/RegionService";
import { useStateValue } from "../../../State/stateProvider";

// eslint-disable-next-line no-useless-escape
const emailTester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

// eslint-disable-next-line no-useless-escape
const phoneTester = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}[-\s\.]?([0-9]{3,6})?/;

const AddUnit = () => {
  const history = useHistory();

  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const {
    regionsDictionary,
    structuresDictionary,
    costCenters,
    companies,
    brands,
    countries,
    restaurantConcepts,
    restaurantAssetTypes,
    franchiseBusinessCoaches
  } = management;

  const [item, setItem] = useState({});

  const openAddPanel = () => setIsCreating(true);
  const dismissAddPanel = () => setIsCreating(false);

  const [isCreating, setIsCreating] = useState(false);
  const [regionEmployees, setRegionEmployees] = useState([]);
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredCoaches, setFilteredCoaches] = useState([]);

  const fields = [
    {
      type: "column",
      key: "1",
      separator: true,
      fields: [
        {
          type: "dropdown",
          label: labels.get('structure'),
          name: "structureId",
          options: structuresDictionary,
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('region'),
          name: "regionId",
          options: regionsDictionary.filter(
              (region) => region.structureId === item.structureId
          ),
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('brand'),
          name: "brandId",
          options: brands,
          disabled: true
        },
        {
          type: "dropdown",
          label: labels.get('country'),
          name: "countryId",
          options: countries,
          required: false,
          disabled: true
        },
        {
          type: "text",
          label: labels.get('restaurant_name'),
          name: "unitName",
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('restaurant_manager'),
          name: "unitManagerId",
          options: regionEmployees,
          required: false,
        },
        {
          type: "dropdown",
          label: labels.get('franchise_business_coach'),
          name: "franchiseBusinessCoachId",
          options: filteredCoaches,
          required: true,
        },
      ],
    },
    {
      type: "column",
      key: "2",
      separator: true,
      fields: [
        {
          type: "dropdown",
          label: labels.get('cost_center'),
          name: "costCenterId",
          options: filteredCenters.filter(costCenter => costCenter.mailboxStatus === "created"),
          required: true,
          showCode: true
        },
        {
          type: "dropdown",
          label: labels.get('company'),
          name: "companyId",
          options: filteredCompanies,
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('concept'),
          name: "restaurantConceptId",
          options: restaurantConcepts,
          required: false,
        },
        {
          type: "dropdown",
          label: labels.get('asset_type'),
          name: "restaurantAssetTypeId",
          options: restaurantAssetTypes,
          required: false,
        },
      ],
    },
    {
      type: "columns",
      key: "3",
      separator: true,
      fields: [
        {
          type: "text",
          label: labels.get('city'),
          name: "city",
        },
        {
          type: "text",
          label: labels.get('street'),
          name: "street",
        },
        {
          type: "text",
          label: labels.get('zip_code'),
          name: "zipCode",
        },
      ],
    },
    {
      type: "columns",
      key: "4",
      separator: true,
      fields: [
        {
          type: "phone",
          label: labels.get('phone_number'),
          name: "phoneNumber",
        },
        {
          type: "phone",
          label: labels.get('franchise_phone_number'),
          name: "franchiseePhoneNumber",
        },
        {
          type: "date",
          label: labels.get('opening_date'),
          name: "openingDate",
          minDate: new Date("1970-01-01"),
          required: true,
        },
        {
          type: "date",
          label: labels.get('close_date'),
          name: "closeTime",
          minDate: new Date("1970-01-01"),
        },
        {
          type: "email",
          label: labels.get('franchise_email'),
          name: "franchiseeEmail",
        },
      ],
    },
    {
      type: "columns",
      key: "5",
      fields: [
        {
          type: "text",
          label: labels.get('comments'),
          name: "comments",
          fullWidth: true,
          multiline: true,
          length: 250,
        }
      ]
    }
  ];

  const confirmChanges = async () => {
    const flatFields = [];
    fields.forEach((entry) => {
      flatFields.push(...entry.fields);
    });

    const requiredDataMissing =
        !item.structureId ||
        !item.regionId ||
        !item.unitName ||
        !item.openingDate ||
        !item.costCenterId ||
        !item.companyId ||
        !item.brandId ||
        !item.countryId;



    const error = Object.entries(item).find(([key, value]) => {
      if (
          flatFields.find((entry) => entry.type === "email" && entry.name === key)
      ) {
        if (value && !emailTester.test(value)) {
          return true;
        }
      }

      if (
          flatFields.find((entry) => entry.type === "phone" && entry.name === key)
      ) {
        if (value && !phoneTester.test(value)) {
          return true;
        }
      }

      return false;
    });

    if (error) {
      toast.error(labels.get('correct_form_errors'));
    } else if (requiredDataMissing) {
      toast.error(labels.get('fill_required_fields'));
    } else {
      const newUnit = await postUnit(item);

      if (newUnit?.data?.id) {
        toast.success(labels.get('unit_added'));
        setItem({});

        dispatch({
          type: "changeLoadingUnits",
          loading: true,
        });

        const structuresResponse = await getAllStructures();
        const structuresUnitsResponse = await getStructuresUnits();
        const unitsResponse = await getAllUnits();
        const regionsResponse = await getAllRegions();

        if (
            structuresResponse?.data &&
            structuresUnitsResponse?.data &&
            unitsResponse?.data &&
            regionsResponse?.data
        ) {
          dispatch({
            type: "changeStructuresRegionsUnits",
            newStructures: structuresResponse.data,
            newStructuresUnits: structuresUnitsResponse.data,
            newUnits: unitsResponse.data,
            newRegions: regionsResponse.data,
          });
        }

        dispatch({
          type: "changeLoadingUnits",
          loading: false,
        });

        history.push(`/restaurant/${newUnit?.data?.id}`);

        dismissAddPanel();
      }
    }
  };

  const changeItem = (value, field) => {
    const newItem = {};

    switch (field) {
      case "restaurantConceptId":
      case "restaurantAssetTypeId":
      case "costCenterId":
      case "companyId":
      case "unitManagerId":
      case "franchiseBusinessCoachId":
        if (value?.id) {
          newItem[field] = value.id;
          newItem[field.replace('Id', '')] = value;
        } else {
          newItem[field] = null;
          newItem[field.replace('Id', '')] = null;
        }
        break;

      case "structureId":
        if (value?.id) {
          if (newItem.structureId !== value.id) {
            newItem.regionId = null;
            newItem.region = null;
          }
          newItem.structureId = value.id;
          newItem.structure = value;
        } else {
          newItem.structureId = null;
          newItem.structure = null;
          newItem.regionId = null;
          newItem.region = null;
        }
        break;

      case "regionId":
        if (value?.id) {
          newItem.regionId = value.id;
          newItem.region = value;

          if (value?.brandId) {
            newItem.brandId = value.brandId;
          } else {
            newItem.brandId = null;
            toast.error('Region does not have brand specified');
          }

          if (value?.countryId) {
            newItem.countryId = value.countryId;
          } else {
            newItem.countryId = null;
            toast.error('Region does not have country specified');
          }

          if (newItem.brandId === null || newItem.countryId === null) {
            newItem.costCenterId = null;
            newItem.companyId = null;
            newItem.franchiseBusinessCoachId = null;
          }

          const filteredCenters = costCenters.filter(costCenter =>
              costCenter.brand && costCenter.country &&
              costCenter.brand.id === value.brandId &&
              costCenter.country.id === value.countryId
          );

          const filteredCompanies = companies.filter(company =>
              company.brand && company.country &&
              company.brand.id === value.brandId &&
              company.country.id === value.countryId
          );

          const filteredCoaches = franchiseBusinessCoaches.filter(coach =>
              coach.brand && coach.country &&
              coach.brand.id === value.brandId &&
              coach.country.id === value.countryId
          );

          setFilteredCenters(filteredCenters);
          setFilteredCompanies(filteredCompanies);
          setFilteredCoaches(filteredCoaches);

          // update region employees based on selected regionId
          getRegionEmployees(value.id)
              .then(employees => {
                setRegionEmployees(
                    employees.data.map(
                        employee => ({
                          id: employee.id,
                          key: employee.id,
                          name: `${employee.firstname} ${employee.surname}`
                        })
                    )
                )
              })
              .catch(e => console.log(e.message));
        } else {
          newItem.regionId = null;
          newItem.region = null;
        }
        break;

      case "sharedEmailAddress":
        newItem.createSharedEmail = !!value;
        break;

      default:
        break;
    }

    setItem(oldItem => ({ ...oldItem, [field]: value, ...newItem }));
  };


  const onRenderFooterContent = () => (
      <div>
        <PrimaryButton onClick={confirmChanges}>{labels.get('save')}</PrimaryButton>
      </div>
  );

  return (
      <div>
        <Button
            name={labels.get('add_new_restaurant')}
            icon="Add"
            className="addButton"
            action={openAddPanel}
        />
        <Panel
            headerText={labels.get('add_new_restaurant')}
            type={PanelType.custom}
            customWidth={"28rem"}
            isLightDismiss
            isOpen={isCreating}
            onDismiss={dismissAddPanel}
            closeButtonAriaLabel={labels.get('close')}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
          <Form item={item} changeItem={changeItem} fields={fields} />
        </Panel>
      </div>
  );
};

export default AddUnit;
