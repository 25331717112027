import React, { useState } from 'react';
import TranslationsService from "../Services/TranslationsService";
import { toast } from "react-toastify";

const FileUpload = ({ onFileUpload, selectedLanguage }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [fileTypeError, setFileTypeError] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setSelectedFile(file);
            setFileTypeError(false);
        } else {
            setSelectedFile(null);
            setFileTypeError(true);
        }
    };

    const handleUpload = async () => {
        if (selectedFile) {
            try {
                await TranslationsService.uploadXlsx(selectedFile, selectedLanguage);
                toast.success("File has been uploaded correctly");
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="fileUpload">
            <span>Upload File</span>
            <div className="uploadContainer">
                <input
                    type="file"
                    className="upload-input"
                    onChange={handleFileChange}
                    accept=".xlsx"
                />
                {selectedFile && !fileTypeError && (
                    <button className="upload-button" onClick={handleUpload}>
                        Upload
                    </button>
                )}
                {fileTypeError && (
                    <p className="error-message">
                        Invalid file type. Please select an Excel file (.xlsx).
                    </p>
                )}
                {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
            </div>

        </div>
    );
};

export default FileUpload;