import React from 'react';

const FileDownload = ({selectedLanguage, onDownload}) => {
    const handleDownload = () => {
        onDownload(selectedLanguage);
    };

    return (
        <button className="download-button" onClick={ handleDownload }>Download File</button>
    );
};

export default FileDownload;
