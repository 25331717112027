import { Client } from "@microsoft/microsoft-graph-client";

const getAuthenticatedClient = (accessToken) => {
  // Initialize Graph client
  const graphClient = Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken);
    }
  });

  return graphClient;
}

export const getUserDetails = async (accessToken) => {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api('/me').get();
  return user;
}
