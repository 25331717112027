import React from 'react';
import Action from '../Action/Action';
import Content from './Content';

const Section = () => {

  return (
    <section className='main__section section'>
      <Action />
      <Content />
    </section>
  )
}

export default Section;