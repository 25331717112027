import React, { useState, useEffect } from 'react';

const countries = [
    { countryCode: "AE", languageCode: "EN" },
    { countryCode: "BE", languageCode: "BE" },
    { countryCode: "CZ", languageCode: "CZ" },
    { countryCode: "ES", languageCode: "ES" },
    { countryCode: "FR", languageCode: "FR" },
    { countryCode: "GB", languageCode: "GB" },
    { countryCode: "HU", languageCode: "HU" },
    { countryCode: "PL", languageCode: "PL" },
    { countryCode: "SA", languageCode: "EN" },
    { countryCode: "SK", languageCode: "EN" },
];

const extractUniqueLanguages = (countries) => {
    return [...new Set(countries.map(country => country.languageCode))];
};

const LanguageSelect = ({onSelectLanguage}) => {
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [availableLanguages, setAvailableLanguages] = useState([]);

    const handleLanguageChange = (e) => {
        const selectedLanguage = e.target.value;
        setSelectedLanguage(selectedLanguage);
        onSelectLanguage(selectedLanguage);
    };

    useEffect(() => {
        setAvailableLanguages(extractUniqueLanguages(countries));
    }, []);

    return (
        <div>
            <select className="autocomplete__input-container" onChange={handleLanguageChange}>
                <option value="">Select Language</option>
                {availableLanguages.map(language => (
                    <option key={language} value={language}>
                        {language}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default LanguageSelect;
