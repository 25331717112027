import React from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Sticky,
  StickyPositionType,
  ScrollbarVisibility,
  ConstrainMode,
  ScrollablePane,
  SelectionMode,
  TextField,
  // Selection,
  // MarqueeSelection
} from "office-ui-fabric-react";
import useWindowSize from "../Common/useWindowSize";
import UserAction from "./UserAction";
import { useStateValue } from "../State/stateProvider";

import "./Users.scss";

const Users = ({
  type,
  users,
  updateUsers,
  sortedColumnKey,
  isSortedDescending,
  filterBy,
  filters
}) => {
  const [{ allLabels: { labels } }] = useStateValue();
  const size = useWindowSize();
  // eslint-disable-next-line no-unused-vars
  // const [selectedItems, setSelectedItems] = useState([]);

  const onColumnClick = (column, additionalField) => {
    updateUsers(column.fieldName, !column.isSortedDescending, additionalField);
  };

  const columns = [
    {
      key: "firstName",
      name: labels.get('name'),
      fieldName: "firstName",
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: labels.get('sorted_az'),
      sortDescendingAriaLabel: labels.get('sorted_za'),
      onColumnClick: (e, column) => onColumnClick(column, "lastName"),
      data: "string",
      onRender: (item) => {
        return (
          <div className="list__username">
            <div className="content">
              <div className="content__name">
                {item.firstName} {item.lastName}
              </div>
              <div className="content__mail">{item.email}</div>
            </div>
            <div className="list__action" data-selection-disabled={true}>
              <UserAction item={item} type={type} />
            </div>
          </div>
        );
      },
      isPadded: true,
      isSorted: sortedColumnKey === "firstName",
      isSortedDescending,
    },
    {
      key: "restaurantName",
      name: labels.get('restaurant_name'),
      fieldName: "restaurantName",
      minWidth: 100,
      maxWidth: 400,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: labels.get('sorted_az'),
      sortDescendingAriaLabel: labels.get('sorted_za'),
      data: "string",
      onColumnClick: (e, column) => onColumnClick(column),
      onRender: (item) => {
        return <div className="list__cell">{item.restaurantName}</div>;
      },
      isPadded: true,
      isSorted: sortedColumnKey === "restaurantName",
      isSortedDescending,
    },
    {
      key: "groupName",
      name: labels.get('group'),
      fieldName: "groupName",
      minWidth: 70,
      maxWidth: 90,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: labels.get('sorted_az'),
      sortDescendingAriaLabel: labels.get('sorted_za'),
      data: "string",
      onColumnClick: (e, column) => onColumnClick(column),
      onRender: (item) => {
        return <div className="list__cell">{item.groupName}</div>;
      },
      isPadded: true,
      isSorted: sortedColumnKey === "groupName",
      isSortedDescending,
    },
    {
      key: "principalName",
      name: labels.get('upn'),
      fieldName: "principalName",
      minWidth: 120,
      maxWidth: 140,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: labels.get('sorted_az'),
      sortDescendingAriaLabel: labels.get('sorted_za'),
      data: "string",
      onColumnClick: (e, column) => onColumnClick(column),
      onRender: (item) => {
        return (
          <div className="list__cell list__cell--no-text-transform">{item.principalName || item.principalId}</div>
        );
      },
      isPadded: true,
      isSorted: sortedColumnKey === "principalName",
      isSortedDescending,
    },
  ];

  const getKey = (item) => {
    return item.userId;
  };

  // const [selection] = useState(
  //   new Selection({
  //     getKey: getKey,
  //     onSelectionChanged: () => {
  //       setSelectedItems(selection.getSelection());
  //     }
  //   })
  // );

  return (
    <div className="users">
      <h2 className="users__heading">
        {type?.replace(/([A-Z])/g, " $1").trim()} {labels.get('user')}
      </h2>
      <div className="users__filters">
        <TextField
          label={`${labels.get('filter_by')} ${labels.get('name')}:`}
          value={filters.name}
          onChange={(_, text) => filterBy(text, "name")}
        />
        <TextField
          label={`${labels.get('filter_by')} ${labels.get('upn')}:`}
          value={filters.principalName}
          onChange={(_, text) => filterBy(text, "principalName")}
        />
      </div>
      
      <div className="users__list">
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            items={users}
            compact={false}
            columns={columns}
            // selectionMode={SelectionMode.multiple}
            selectionMode={SelectionMode.none}
            setKey="userId"
            getKey={getKey}
            constrainMode={ConstrainMode.unconstrained}
            layoutMode={
              size.width > 1024
                ? DetailsListLayoutMode.justified
                : DetailsListLayoutMode.fixedColumns
            }
            isHeaderVisible={true}
            // selection={selection}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn={labels.get('toggle_selection')}
            ariaLabelForSelectAllCheckbox={labels.get('toggle_selection_for_all_items')}
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              return (
                <Sticky
                  stickyPosition={StickyPositionType.Header}
                  isScrollSynced={true}
                  stickyBackgroundColor="transparent"
                >
                  <div>{defaultRender(headerProps)}</div>
                </Sticky>
              );
            }}
          />
          {!users || users.length === 0 ? (
            <div style={{ textAlign: "center" }}>{labels.get('there_are_no_users')}</div>
          ) : null}
          {/* <MarqueeSelection selection={selection}>
          </MarqueeSelection> */}
        </ScrollablePane>
      </div>
    </div>
  );
};

export default Users;
