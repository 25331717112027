import { ApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export const getUnassignedUsers = async () => {
  try {
    const response = await Client.unassigned();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const getAssignedUsers = async () => {
  try {
    const response = await Client.assigned();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const getAllUsers = async (id) => {
  try {
    const response = await Client.all(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const getGroupedUsers = async () => {
  try {
    const response = await Client.groups();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const getUnitAdmins = async () => {
  try {
    const response = await Client.unitAdmins();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const postAssignUserToGroup = async (data) => {
  try {
    const response = await Client.assign_User_to_Group(data);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const postAssignUserToRegion = async (data) => {
  try {
    const response = await Client.assign_User_to_Region(data);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const postAssignUserToUnit = async (data) => {
  try {
    const response = await Client.assign_User_to_Unit(data);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const revokeUserAccess = async (data) => {
  try {
    const response = await Client.revoke_User_Access(data);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const createNewUser = async (data) => {
  try {
    const response = await Client.create_New_User(data);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
}

export const inviteNewUser = async (data) => {
  try {
    const response = await Client.invite_User(data);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      toast.error('Something go wrong');
    }
    return false;
  }
}