import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { StateProvider } from './stateProvider';
import App from '../../App';
import { ApiClient, changeToken } from '../Services/ApiService';
import { authReducer, authReducerInitialState, userAgentApplication } from './authReducer';
import { managementReducer, managementReducerInitialState } from './managementReducer';
import { GdprContainer } from '../../pages/gdpr'
import { labelsReducer, labelsReducerInitialState } from './labelsReducer';

import Router from './Router';

const StateComponent = (props) => {

  const reducer = ({ auth, management, allLabels }, action) => {
    if (action.type === 'changeAuthenticated') {
      try {
        changeToken(action.newGroupAccessToken, userAgentApplication);
      } catch(e) {
        console.log(e);
      }
    } else if (!ApiClient && auth.groupAccessToken) {
      changeToken(auth.groupAccessToken, userAgentApplication)
    }

    return {
      auth: authReducer(auth, action),
      management: managementReducer(management, action),
      allLabels: labelsReducer(allLabels, action),
    }
  };

  const initialState = {
    auth: authReducerInitialState,
    management: managementReducerInitialState,
    allLabels: labelsReducerInitialState,
  }

  return (
      <StateProvider initialState={initialState} reducer={reducer}>
        <Router>
          <Switch>
              <Route path="/gdpr" component={GdprContainer} />
              <Route>
                  <App />
              </Route>
          </Switch>
        </Router>
      </StateProvider>
  );
}

export default StateComponent;