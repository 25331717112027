const computeStructuresWithUnits = (structures, units) => {
  const data =
    structures?.map((structure) => {
      const structureUnits = units
        .filter((unitGroup) => unitGroup.structureId === structure.id)
        .map((unitGroup) => unitGroup.units)
        .flat();
      if (structureUnits?.length > 0) {
        structure.units = structureUnits;
      }
      return structure;
    })?.sort((a, b) => a.name.localeCompare(b.name)) || [];

  return data;
};

const computeDictionary = (entities) => {
  return entities.map((entity) => ({
    id: entity.id,
    key: entity.id,
    text: entity.name || entity.unitName,
    name: entity.name || entity.unitName,
    structureId: entity.structureId,
    brandId: entity.brandId || null,
    countryId: entity.countryId || null,
    structureCode: entity.structureCode || entity.structure?.code,
  })).sort((a, b) => a.text.localeCompare(b.text));
};

const computeRegions = (regions, parentId, parentRegionsIds) => {
  const arr = Array.isArray(regions) ? regions : [regions];

  return arr.sort((a, b) => a.name.localeCompare(b.name)).reduce((result, value) => {
    const parentIds = parentId ? [...(parentRegionsIds || []), parentId] : [...(parentRegionsIds || [])]
    result.push({
      ...value,
      parentIds,
      parentId,
    });

    if (value.subRegions) {
      result = result.concat(computeRegions(value.subRegions, value.id, parentIds));
      delete value.subRegions;
    }

    return result;
  }, []);
};

const computeRegionsDictionary = (newRegions) => {
  const regions = JSON.parse(JSON.stringify(newRegions));

  return computeRegions(regions).map((entity) => ({
    id: entity.id,
    key: entity.id,
    name: entity.name,
    text: entity.name,
    structureId: entity.structureId,
    parentId: entity.parentId,
    parentIds: entity.parentIds,
    brandId: entity?.brandId,
    countryId: entity?.countryId,
  }));
};

const computeRegionsWithUnits = (regions, units) => {
  return regions.map((region) => {
    const regionUnits = units.filter((unit) => unit.region.id === region.id);
    let subRegions = region.subRegions;

    if (region.subRegions) {
      subRegions = computeRegionsWithUnits(region.subRegions, units);
    }

    const newRegion = { ...region, subRegions, units: regionUnits };

    return newRegion;
  }).sort((a, b) => a.name.localeCompare(b.name));
};

export const managementReducer = (state, action) => {

  switch (action.type) {
    case "changeLoadingUnits": {
      return {
        ...state,
        loadingUnits: action.loading
      }
    }
    case "changeLoadingDictionares": {
      return {
        ...state,
        loadingDictionares: action.loading
      }
    }
    case "changeRegions": {
      const regionsDictionary = computeRegionsDictionary(action.newRegions);
      return {
        ...state,
        regions: action.newRegions,
        regionsDictionary,
      };
    }
    case "changeUnits": {
      const structuresWithUnits = computeStructuresWithUnits(
        state.structures,
        action.newStructuresUnits
      );
      const unitsDictionary = computeDictionary(action.newUnits);

      return {
        ...state,
        structuresWithUnits,
        structuresUnits: action.newStructuresUnits,
        units: action.newUnits,
        unitsDictionary,
      };
    }
    case "changeStructuresRegionsUnits": {
      const structuresDictionary = computeDictionary(action.newStructures);
      const regions = action.newRegions.map(region => {
        if (region.depthLevel === 0) {
          const structureName = structuresDictionary.find(structure => structure.key === region.structureId)?.text;
          return { ...region, name: ` ${structureName} - ${region.name}`, structureName };
        }

        return region;
      });
      const regionsDictionary = computeRegionsDictionary(regions);
      const structuresWithUnits = computeStructuresWithUnits(
        action.newStructures,
        action.newStructuresUnits
      );
      const unitsDictionary = computeDictionary(action.newUnits);
      const regionsWithUnits = computeRegionsWithUnits(
        regions,
        action.newUnits
      );
      
      return {
        ...state,
        structuresWithUnits,
        regionsWithUnits,
        regionsDictionary,
        regions: action.newRegions,
        structuresUnits: action.newStructuresUnits,
        units: action.newUnits,
        structures: action.newStructures,
        structuresDictionary,
        unitsDictionary,
      };
    }
    case "changeBrands":
      return {
        ...state,
        brands: action.newBrands?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
      };
    case "changeCompanies":
      return {
        ...state,
        companies: action.newCompanies?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
      };
    case "changeCostCenters":
      return {
        ...state,
        costCenters: action.newCostCenters?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
      };
    case "changeCountries":
      return {
        ...state,
        countries: action.newCountries?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
      };
    case "changeDictionaries":
      return {
        ...state,
        countries: action.newDictionaries.countries?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        costCenters: action.newDictionaries.costCenters?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        companies: action.newDictionaries.companies?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        brands: action.newDictionaries.brands?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        franchiseBusinessCoaches: action.newDictionaries.franchiseBusinessCoaches?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        restaurantConcepts: action.newDictionaries.restaurantConcepts?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        restaurantAssetTypes: action.newDictionaries.restaurantAssetTypes?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
      };
    case "changeJobTitles":
      return {
        ...state,
        jobTitles: action.newJobTitles?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
      };
    case "changeEmployeeTypes":
      return {
        ...state,
        employeeTypes: action.newEmployeeTypes?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
      };
    case "changeRefreshData":
      return {
        ...state,
        refreshData: state.refreshData + 1,
      };
      case "changeFranchiseBusinessCoaches":
        return {
          ...state,
          franchiseBusinessCoaches: action.newFranchiseBusinessCoaches?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        };
      case "changeRestaurantAssetTypes":
        return {
          ...state,
          restaurantAssetTypes: action.newRestaurantAssetTypes?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        };
      case "changeRestaurantConcepts":
        return {
          ...state,
          restaurantConcepts: action.newRestaurantConcepts?.map(entry => ({ ...entry, key: entry.id, text: entry.name })) || [],
        };
    default:
      return state;
  }
};

export const managementReducerInitialState = {
  brands: [],
  companies: [],
  costCenters: [],
  countries: [],
  employeeTypes: [],
  franchiseBusinessCoaches: [],
  jobTitles: [],
  refreshData: 0,
  regions: [],
  regionsDictionary: [],
  regionsWithUnits: [],
  structures: [],
  structuresDictionary: [],
  structuresUnits: [],
  structuresWithUnits: [],
  units: [],
  unitsDictionary: [],
  users: [],
  loadingUnits: true,
  loadingDictionares: false
};
