import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Spinner } from "office-ui-fabric-react";

import { useStateValue } from "../State/stateProvider";
import {
  createRestaurantAssetType,
  updateRestaurantAssetType,
  deleteRestaurantAssetType,
  getAllRestaurantsAssetTypes,
} from "../Services/DictionaryService";
import DictionaryList from "./DictionaryList";

const RestaurantAssetTypesContainer = () => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const { restaurantAssetTypes, loadingDictionares } = management;

  const refreshRestaurantAssetTypes = useCallback(async () => {
    const dictionaryResponse = await getAllRestaurantsAssetTypes();

    if (dictionaryResponse.data) {
      const newRestaurantAssetTypes = dictionaryResponse.data;

      dispatch({
        type: "changeRestaurantAssetTypes",
        newRestaurantAssetTypes,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    refreshRestaurantAssetTypes();
  }, [refreshRestaurantAssetTypes]);

  const handleDelete = async (key) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await deleteRestaurantAssetType(key);

      await refreshRestaurantAssetTypes();

      if (success) {
        toast.success(labels.get('restaurant_asset_type_has_been_successfully_deleted'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleEdit = async (key, text) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await updateRestaurantAssetType(key, text);

      await refreshRestaurantAssetTypes();
      if (success) {
        toast.success(labels.get('restaurant_asset_type_has_been_successfully_updated'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleAdd = async (text) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await createRestaurantAssetType(text);

      await refreshRestaurantAssetTypes();
      if (success) {
        toast.success(labels.get('restaurant_asset_type_has_been_successfully_added'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  return loadingDictionares ? (
    <div className="center">
      <Spinner className="spinner" label={`${labels.get('processing')}...`} />
    </div>
  ) : (
    <DictionaryList
      dictionary={restaurantAssetTypes}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onAdd={handleAdd}
      dictionaryName="Restaurant Asset Type"
      withManualCodeAddition
      withCodeColumn
    />
  );
};

export default RestaurantAssetTypesContainer;
