import axios from "axios";
import { Client } from "./Api";
import { userAgentApplication } from "../State/authReducer";
import { history } from "../State/Router";

export let ApiClient = null;
const axiosInstance = axios.create();
export const UnAuthApiClient = new Client(process.env.REACT_APP_API_URL, axiosInstance);

export const changeToken = (token, userAgentApplication) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
        config.validateStatus = (status) => status >= 200 && status !== 401 && status !== 403;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const loginRequest = {
        scopes: ["user.read", process.env.REACT_APP_GROUP_SCOPE],
        prompt: "select_account"
      };

      if (error.response.status === 401 || error.response.status === 403) {
        const accessToken = await userAgentApplication.acquireTokenSilent({
          scopes: [process.env.REACT_APP_GROUP_SCOPE],
        });

        if(!accessToken) {
          userAgentApplication.loginRedirect(loginRequest);
        } else {
          return;
        }
      }

      return Promise.reject(error);
    }
  );

  ApiClient = new Client(process.env.REACT_APP_API_URL, axiosInstance);
};

export const handleLogout = () => {
  localStorage.clear();
  sessionStorage.clear();
  const cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }

  const loginRequest = {
    scopes: ["user.read", process.env.REACT_APP_GROUP_SCOPE],
    prompt: "select_account",
  };

  history.replace("/");
  userAgentApplication.loginRedirect(loginRequest);
};
