import React from 'react';
import { SearchBox } from 'office-ui-fabric-react';
import { useStateValue } from "../../../State/stateProvider";

const Search = ({ changeSearch }) => {
  const [{ allLabels: { labels } }] = useStateValue();

  return (
    <div style={{ margin: `0 0.5rem 0.5rem` }}>
      <SearchBox
        styles={{ root: { width: 469, maxWidth: '100%' } }}
        placeholder={labels.get('search_restaurant')}
        onChange={(_, newValue) => changeSearch(newValue)}
      />
    </div>
  );
};

export default Search;
