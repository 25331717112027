import React from "react";
import { Icon } from "office-ui-fabric-react";
import "./Policies.scss";

const countries = [
    { countryCode: "AE", languageCode: "EN" },
    { countryCode: "BE", languageCode: "BE" },
    { countryCode: "CZ", languageCode: "CZ" },
    { countryCode: "ES", languageCode: "ES" },
    { countryCode: "FR", languageCode: "FR" },
    { countryCode: "GB", languageCode: "GB" },
    { countryCode: "HU", languageCode: "HU" },
    { countryCode: "PL", languageCode: "PL" },
    { countryCode: "SA", languageCode: "EN" },
    { countryCode: "SK", languageCode: "EN" },
];

const generatePdfUrl = (country) => {
    return `https://apicfdb.partners-amrest.eu/api/Storage/download/${country.countryCode}/Privacy_Policy_for_Franchisee_${country.languageCode}.pdf`;
};

const links = countries.map((country) => ({
    name: `Privacy Policy for Franchisee ${country.countryCode}`,
    url: generatePdfUrl(country),
}));

const PoliciesContainer = () => {
    const handleLinkClick = (event, url) => {
        event.preventDefault();
        let viewerUrl = url.includes('?') ? `${url}&inline=true` : `${url}?inline=true`;
        window.open(viewerUrl, '_blank');
    };

    return (
        <div className="policies__container">
            <h1>List of Privacy Policies</h1>
            <ul>
                {links.map((link) => (
                    <li
                        key={link.name}
                        onClick={(event) => handleLinkClick(event, link.url)}
                        tabIndex="0"
                        className="clickable-item"
                    >
                        <Icon iconName={'TextDocument'} />
                        <a
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {link.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PoliciesContainer;
