import React, { Fragment, useEffect, useState } from "react";
import {
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter,
  TextField,
  IconButton,
} from "office-ui-fabric-react";

import { useStateValue } from "../State/stateProvider";
import Autocomplete from "../Common/Autocomplete";

import "./DictionaryList.scss";

const DeleteDialog = ({ onClose, onConfirm, dictionaryName, entry }) => {
  const [{ allLabels: { labels } }] = useStateValue();

  const handleConfirm = () => {
    onConfirm(entry.id);
  };

  return (
    <Dialog
      hidden={!entry}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.normal,
        title: `Delete ${dictionaryName}`,
      }}
    >
      <p>{labels.get('are_you_sure_you_want_to_delete')} {entry?.name}?</p>
      <DialogFooter>
        <DefaultButton onClick={onClose} text="Cancel" />
        <PrimaryButton onClick={handleConfirm} text="Delete" />
      </DialogFooter>
    </Dialog>
  );
};

const EditDialog = ({
  onClose,
  onConfirm,
  dictionaryName,
  entry,
  withManualCodeAddition,
  withBrand,
  withCountry,
  codeType,
  withLicenses,
  disableCodeEditing
}) => {
  const [{management: {brands, countries}, allLabels: { labels }}] = useStateValue();

  const [name, setName] = useState("");
  const [structureId, setStructureId] = useState("");
  const [code, setCode] = useState(codeType === "number" ? 0 : "");
  const [limitOfLicenses, setLimitOfLicenses] = useState(0);
  const [brand, setBrand] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");
  const [brandId, setBrandId] = useState("");

  const handleConfirm = () => {
    onConfirm(entry.id, { code, name, limitOfLicenses, structureId, brand, country, countryId, brandId });
  };

  useEffect(() => {
    if (entry) {
      setName(entry.name);
      setCode(entry.code);
      setStructureId(entry.structureId);
      setLimitOfLicenses(entry.limitOfLicenses);
      setBrand(entry.brand);
      setCountry(entry.country);
      setCountryId(entry.country && entry.country.id);
      setBrandId(entry.brand && entry.brand.id);
    } else {
      setName("");
      setStructureId("");
      setCode(codeType === "number" ? 0 : "");
      setLimitOfLicenses(0);
      setBrand("");
      setCountry("");
      setCountryId("0");
      setBrandId("0");
    }
  }, [entry, codeType]);

  const handleChange = (_, value) => {
    setName(value);
  };

  const handleChangeCode = (_, value) => {
    setCode(value);
  };

  const handleChangeLicenses = (_, value) => {
    setLimitOfLicenses(value);
  };

  const handleChangeBrand = (_, value) => {
    setBrand(value.name);
    setBrandId(value.id);
  }

  const handleChangeCountry = (_, value) => {
    setCountry(value.name);
    setCountryId(value.id);
  }

  return (
    <Dialog
      hidden={!entry}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.normal,
        title: `${labels.get('edit')} ${dictionaryName}`,
      }}
    >
      <TextField
        label={`${dictionaryName}`}
        onChange={handleChange}
        value={name}
        required
      />
      {withManualCodeAddition ? (
        <TextField
          label="Code"
          onChange={handleChangeCode}
          value={code}
          type={codeType || "text"}
          required
          disabled={disableCodeEditing}
        />
      ) : null}
      {withLicenses ? (
        <TextField
          label={labels.get('number_of_licenses')}
          onChange={handleChangeLicenses}
          value={limitOfLicenses}
          type="number"
          required
        />
      ) : null}
      {withBrand ? (
        <Autocomplete
          label={labels.get('brand')}
          options={brands}
          onChange={handleChangeBrand}
          value={brand}
          type="text"
          required
        />
      ) : null}
      {withCountry ? (
        <Autocomplete
          label={labels.get('country')}
          options={countries}
          onChange={handleChangeCountry}
          value={country}
          type="text"
          required
        />
      ) : null}
      <DialogFooter>
        <DefaultButton onClick={onClose} text={labels.get('cancel')} />
        <PrimaryButton onClick={handleConfirm} text={labels.get('confirm')} />
      </DialogFooter>
    </Dialog>
  );
};

const AddDialog = ({
  onClose,
  onConfirm,
  dictionaryName,
  open,
  withManualCodeAddition,
  withLicenses,
  withBrand,
  withCountry,
  codeType,
  withStructureId,
  structures,
}) => {
  const [{management: {brands, countries}, allLabels: { labels }}] = useStateValue();

  const [name, setName] = useState("");
  const [structureId, setStructureId] = useState("");
  const [code, setCode] = useState(codeType === "number" ? 0 : "");
  const [limitOfLicenses, setLimitOfLicenses] = useState(0);
  const [brand, setBrand] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");
  const [brandId, setBrandId] = useState("");

  const handleConfirm = () => {
    onConfirm({ code, name, limitOfLicenses, structureId, brand, country, countryId, brandId });
  };

  useEffect(() => {
    setName("");
    setStructureId("");
    setCode(codeType === "number" ? 0 : "");
    setLimitOfLicenses(0);
    setBrand("");
    setCountry("");
    setCountryId("");
    setBrandId("")
  }, [codeType, open]);

  const handleChange = (_, value) => {
    setName(value);
  };

  const handleChangeCode = (_, value) => {
    setCode(value);
  };

  const handleChangeLicenses = (_, value) => {
    setLimitOfLicenses(value);
  };

  const handleChangeStructureId = (_, value) => {
    setStructureId(value.id);
  };

  const handleChangeBrand = (_, value) => {
    setBrand(value.name);
    setBrandId(value.id);
  }

  const handleChangeCountry = (_, value) => {
    setCountry(value.name);
    setCountryId(value.id);
  }

  return (
    <Dialog
      hidden={!open}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.normal,
        title: `Add ${dictionaryName}`,
      }}
    >
      {withStructureId ? (
        <Autocomplete
          label={labels.get('structure')}
          options={structures}
          value={structureId}
          required
          onChange={handleChangeStructureId}
        />
      ) : null}
      <TextField
        label={`New ${dictionaryName}`}
        onChange={handleChange}
        value={name}
        required
      />
      {withManualCodeAddition ? (
        <TextField
          label={labels.get('code')}
          onChange={handleChangeCode}
          value={code}
          type={codeType || "text"}
          required
          maxLength={10}
        />
      ) : null}
      {withLicenses ? (
        <TextField
          label={labels.get('number_of_licenses')}
          onChange={handleChangeLicenses}
          value={limitOfLicenses}
          type="number"
          required
        />
      ) : null}
      {withBrand ? (
        <Autocomplete
          label={labels.get('brand')}
          options={brands}
          onChange={handleChangeBrand}
          value={brand}
          type="text"
          required
        />
      ) : null}
      {withCountry ? (
        <Autocomplete
          label={labels.get('country')}
          options={countries}
          onChange={handleChangeCountry}
          value={country}
          type="text"
          required
        />
      ) : null}
      <DialogFooter>
        <DefaultButton onClick={onClose} text={labels.get('cancel')} />
        <PrimaryButton onClick={handleConfirm} text={labels.get('confirm')} />
      </DialogFooter>
    </Dialog>
  );
};

const List = ({
  dictionary,
  withCodeColumn,
  withLicenses,
  withUsedLicenses,
  withEmail,
  withBrand,
  withCountry,
  withMailboxStatus,
  handleEdit,
  handleDelete,
  handleMoveUp,
  handleMoveDown,
}) => {
  const [{ allLabels: { labels } }] = useStateValue();

  let columns = 2;

  if (withCodeColumn) {
    columns += 1;
  }

  if (withLicenses) {
    columns += 1;
  }

  if (withUsedLicenses) {
    columns += 1;
  }

  if (withEmail) {
    columns += 1;
  }

  if(withBrand) {
    columns += 1;
  }

  if(withCountry) {
    columns += 1;
  }

  if (withMailboxStatus) {
    columns += 1;
  }

  return (
    <div className="dictionary-list" style={{ "--columns": columns }}>
      {withCodeColumn ? (
        <span className="dictionary-list__item--bold">{labels.get('code')}</span>
      ) : null}
      <span className="dictionary-list__item--bold">{labels.get('name')}</span>
      {withLicenses ? (
        <span className="dictionary-list__item--bold">{labels.get('licenses')}</span>
      ) : null}
      {withUsedLicenses ? (
        <span className="dictionary-list__item--bold">{labels.get('used_licences')}</span>
      ) : null}
      {withEmail ? (
        <span className="dictionary-list__item--bold">{labels.get('email_work')}</span>
      ) : null}
      {withBrand ? (
        <span className="dictionary-list__item--bold">{labels.get('brand')}</span>
      ) : null}
      {withCountry ? (
        <span className="dictionary-list__item--bold">{labels.get('country')}</span>
      ) : null}
      {withMailboxStatus ? (
        <span className="dictionary-list__item--bold">{labels.get('mailbox_status')}</span>
      ) : null}
      <span className="dictionary-list__item--bold dictionary-list__item--center">
        {labels.get('action')}
      </span>
      {dictionary
        ? dictionary.sort((a,b)=>a.code-b.code).map((entry) => (
            <Fragment key={entry.id}>
              {withCodeColumn ? (
                <span className="dictionary-list__item--bold">
                  {entry.code}
                </span>
              ) : null}
              <span>{entry.name}</span>
              {withLicenses ? <span>{entry.limitOfLicenses}</span> : null}
              {withUsedLicenses ? <span>{entry.usedLicensed}</span> : null}
              {withEmail ? <span>{entry.emailAddress}</span> : null}
              {withBrand ? <span>{entry.brand?.name}</span> : null}
              {withCountry ? <span>{entry.country?.name}</span> : null}
              {withMailboxStatus ? <span className="dictionary-list__item--capitalize">{entry.mailboxStatus}</span> : null}
              <span>
                {
                  handleMoveUp && handleMoveDown &&
                    <>
                      <IconButton
                        onClick={() => handleMoveUp(entry.id)}
                        style={{color: '#000'}}
                        title={labels.get('move_job_title_up')}
                        iconProps={{ iconName: "ChevronUpMed" }}
                      />
                      <IconButton
                        onClick={() => handleMoveDown(entry.id)}
                        style={{color: '#000'}}
                        title={labels.get('move_job_title_down')}
                        iconProps={{ iconName: "ChevronDownMed" }}
                      />
                    </>
                }
                <IconButton
                  onClick={handleEdit(entry)}
                  iconProps={{ iconName: "edit" }}
                  title={labels.get('edit')}
                />
                <IconButton
                  onClick={handleDelete(entry)}
                  iconProps={{ iconName: "delete" }}
                  title={'Delete'}
                />
              </span>
            </Fragment>
          ))
        : null}
    </div>
  );
};

const DictionaryList = ({
  dictionary,
  onAdd,
  onEdit,
  onDelete,
  onMoveUp,
  onMoveDown,
  dictionaryName,
  withManualCodeAddition,
  withCodeColumn,
  codeType,
  structures,
  withLicenses,
  withStructureId,
  withUsedLicenses,
  withEmail,
  withBrand,
  withCountry,
  withMailboxStatus,
  disableCodeEditing,
}) => {
  const [{ allLabels: { labels } }] = useStateValue();

  const [deleteDialogEntry, setDeleteDialogEntry] = useState(null);
  const [editDialogEntry, setEditDialogEntry] = useState(null);
  const [addDialog, setAddDialog] = useState(false);

  const handleDelete = (entry) => () => {
    setDeleteDialogEntry(entry);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogEntry(null);
  };

  const confirmDeleteDialog = (id) => {
    setDeleteDialogEntry(null);
    onDelete(id);
  };

  const handleEdit = (entry) => () => {
    setEditDialogEntry(entry);
  };

  const closeEditDialog = () => {
    setEditDialogEntry(null);
  };

  const confirmEditDialog = (id, entry) => {
    setEditDialogEntry(null);
    onEdit(id, entry);
  };

  const handleAdd = () => {
    setAddDialog(true);
  };

  const closeAddDialog = () => {
    setAddDialog(false);
  };

  const confirmAddDialog = (entry) => {
    setAddDialog(false);
    onAdd(entry);
  };

  return (
    <>
      <DeleteDialog
        entry={deleteDialogEntry}
        dictionaryName={dictionaryName}
        onClose={closeDeleteDialog}
        onConfirm={confirmDeleteDialog}
      />
      <EditDialog
        entry={editDialogEntry}
        dictionaryName={dictionaryName}
        onClose={closeEditDialog}
        onConfirm={confirmEditDialog}
        withManualCodeAddition={withManualCodeAddition}
        withCodeColumn={withCodeColumn}
        codeType={codeType}
        withLicenses={withLicenses}
        withStructureId={withStructureId}
        structures={structures}
        disableCodeEditing={disableCodeEditing}
        withBrand={withBrand}
        withCountry={withCountry}
      />
      <AddDialog
        open={addDialog}
        dictionaryName={dictionaryName}
        onClose={closeAddDialog}
        onConfirm={confirmAddDialog}
        withManualCodeAddition={withManualCodeAddition}
        withCodeColumn={withCodeColumn}
        codeType={codeType}
        withLicenses={withLicenses}
        withStructureId={withStructureId}
        structures={structures}
        withBrand={withBrand}
        withCountry={withCountry}
      />
      <PrimaryButton
        name={`Add new ${dictionaryName}`}
        icon="Add"
        iconProps={{ iconName: "add" }}
        onClick={handleAdd}
      >
        {labels.get('add_new')} {dictionaryName}
      </PrimaryButton>
      <List
        dictionary={dictionary}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleMoveUp={onMoveUp}
        handleMoveDown={onMoveDown}
        withCodeColumn={withCodeColumn}
        withLicenses={withLicenses}
        withUsedLicenses={withUsedLicenses}
        withEmail={withEmail}
        withMailboxStatus={withMailboxStatus}
        withBrand={withBrand}
        withCountry={withCountry}
      />
    </>
  );
};

export default DictionaryList;
