import React from 'react';
import './Modal.scss';
import { useStateValue } from "../State/stateProvider";
import { PrimaryButton } from 'office-ui-fabric-react';

const Modal = ({ show, onClose, children }) => {
    const [{ allLabels: { labels } }] = useStateValue();
    if (!show) {
        return null;
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h1>{labels.get('modal_title')}</h1>
                <p>{labels.get('modal_content')}</p>
                <div className='buttons-field'>
                    {children}
                    <PrimaryButton className='cancel-button' onClick={onClose}>Close</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default Modal;