import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'office-ui-fabric-react';
import {
  getUnassignedUsers,
  getAssignedUsers,
  getAllUsers,
  getUnitAdmins,
} from '../Services/UserService';
import Users from './Users';
import { useStateValue } from '../State/stateProvider';
import usePrevious from '../Common/usePrevious'

import './Users.scss';

const UsersContainer = () => {
  const { type } = useParams();

  const [{ management, allLabels: { labels } }] = useStateValue();
  const { refreshData } = management;

  const [users, setUsers] = useState(null);
  const [pureUsers, setPureUsers] = useState(null);
  const [sortedColumnKey, setSortedColumnKey] = useState(null);
  const [sortedDescending, setSortedDescending] = useState(false);
  const [sortedAdditionalField, setSortedAdditionalField] = useState(false);
  
  const [filters, setFilters] = useState({});

  const filterUsers = useCallback((allUsers) => {
    if (allUsers) {
      const newUsers = allUsers.filter((user) => {
        let valid = true;

        for (let [key, value] of Object.entries(filters)) {
          if (key === 'name') {
            const name = `${user.firstName} ${user.lastName}`;
            if (!name.toLowerCase().includes(value.toLowerCase())) {
              valid = false;
              break;
            }
          } else if (typeof user[key] === 'string') {
            if (!user[key].toLowerCase().includes(value.toLowerCase())) {
              valid = false;
              break;
            }
          } else if (typeof user[key] === 'number') {
            if (!user[key].toString().toLowerCase().includes(value.toLowerCase())) {
              valid = false;
              break;
            }
          } else if (user[key] instanceof Object) {
            if (user[key].id !== value) {
              valid = false;
              break;
            }
          }
        }

        return valid;
      });

      return newUsers;
    }

    return allUsers;
  }, [filters]);

  const updateUsers = (columnKey, isSortedDescending, additionalField) => {
    setSortedColumnKey(columnKey);
    setSortedDescending(isSortedDescending);
    setSortedAdditionalField(additionalField);
    let newUsers = [];
    const sortDirection = isSortedDescending ? 1 : -1;
    if (additionalField) {
      newUsers = users
        .slice()
        .sort(
          (a, b) =>
            (`${a[columnKey] || ''} ${a[additionalField] || ''}` <
            `${b[columnKey] || ''} ${b[additionalField] || ''}`
              ? 1
              : -1) * sortDirection
        );
    } else {
      newUsers = users
        .slice()
        .sort(
          (a, b) =>
            ((a[columnKey] || '') < (b[columnKey] || '') ? 1 : -1) *
            sortDirection
        );
    }

    // setUsers(newUsers);
    setUsers(filterUsers(newUsers));
  };

  const fetchData = useCallback(
    async (fetchType) => {
      setUsers(null);
      setPureUsers(null);
      let newUsers = [];

      if (fetchType) {
        switch (fetchType) {
          case 'all': {
            newUsers = await getAllUsers();
            break;
          }
          case 'assigned': {
            newUsers = await getAssignedUsers();
            break;
          }
          case 'unassigned': {
            newUsers = await getUnassignedUsers();
            break;
          }
          case 'restaurantAdmins': {
            newUsers = await getUnitAdmins();
            break;
          }
          default:
            break;
        }
      }

      if (newUsers?.data) {
        newUsers.data.map((user) => {
          const newUser = user;
          newUser.groupName = newUser.group?.displayName;
          newUser.restaurantName = newUser.restaurant?.name;
          return newUser;
        });
        if (sortedColumnKey) {
          const sortDirection = sortedDescending ? 1 : -1;
          if (sortedAdditionalField) {
            const sortedData = newUsers.data
              .slice()
              .sort(
                (a, b) =>
                  (`${a[sortedColumnKey] || ''} ${a[sortedAdditionalField] ||
                    ''}` <
                  `${b[sortedColumnKey] || ''} ${b[sortedAdditionalField] ||
                    ''}`
                    ? 1
                    : -1) * sortDirection
              );
            // setUsers(sortedData);
            setPureUsers(sortedData);
            setUsers(filterUsers(sortedData));
          } else {
            const sortedData = newUsers.data
              .slice()
              .sort(
                (a, b) =>
                  ((a[sortedColumnKey] || '') < (b[sortedColumnKey] || '')
                    ? 1
                    : -1) * sortDirection
              );
            // setUsers(sortedData);
            setPureUsers(sortedData);
            setUsers(filterUsers(sortedData));
          }
        } else {
          // setUsers(newUsers.data);
          setPureUsers(newUsers.data);
          setUsers(filterUsers(newUsers.data));
        }
      } else {
        setPureUsers([]);
        setUsers([]);
      }
    },
    [sortedColumnKey, sortedDescending, sortedAdditionalField, filterUsers]
  );

  const filterBy = (filterValue, column) => {
    let value = filterValue;

    setFilters((oldFilter) => ({ ...oldFilter, [column]: value }));
  };

  useEffect(() => {
    setUsers(filterUsers(pureUsers));
  }, [filters, filterUsers, pureUsers]);

  const previousType = usePrevious(type);
  const previousRefreshData = usePrevious(refreshData);

  useEffect(() => {
    if (type !== previousType || refreshData !== previousRefreshData) {
      fetchData(type);
    }
  }, [type, refreshData, previousType, previousRefreshData, fetchData]);

  return (
    <>
      {users !== null ? (
        <Users
          type={type}
          users={users}
          updateUsers={updateUsers}
          sortedColumnKey={sortedColumnKey}
          isSortedDescending={sortedDescending}
          filterBy={filterBy}
          filters={filters}
        />
      ) : (
        <div className='center'><Spinner className="spinner" label={`${labels.get('loading_users')}...`} /></div>
      )}
    </>
  );
};

export default UsersContainer;
