import React, { useCallback, useState } from "react";
import { useAutocomplete } from "@material-ui/lab";
import { Fade, Popper } from "@material-ui/core";
import { Icon, Checkbox } from "office-ui-fabric-react";

import "./Autocomplete.scss";

const getOptionSelected = (option, value) => {
  if (value instanceof Array) {
    return value?.some((entry) => entry.includes(option.key));
  } else if (value instanceof Object) {
    return value?.key === option?.key;
  }

  return value === option?.key;
};

const Autocomplete = ({
  value,
  onChange,
  multiple,
  label,
  options = [],
  disabled,
  required,
  errorMessage,
  disableCloseOnSelect,
  disablePortal,
  small,
  showCode
}) => {
  const getOptionLabel = (option) => {
    let optionToMatch = option;

    if (!(option instanceof Object)) {
      const foundEntry = options.find((entry) => entry.key === option || entry.name === option);

      if (foundEntry) {
        optionToMatch = foundEntry;
      } else {
        return "";
      }
    }
    
    if (showCode && option.code) {
      if (optionToMatch.name) {
        return `${optionToMatch.name} ${optionToMatch.code}`;
      }

      if (optionToMatch.text) {
        return `${optionToMatch.text} ${optionToMatch.code}`
      }

      return optionToMatch.code;
    }

    return optionToMatch.name || optionToMatch.text || "";
  };

  const handleChange = (e, value) => {
    e.stopPropagation();
    onChange(e, value);
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: `autocomplete-${label}`,
    options,
    getOptionLabel,
    getOptionSelected,
    value: value || null,
    multiple,
    required,
    onChange: handleChange,
    disableCloseOnSelect,
  });

  const [containerWidth, setContainerWidth] = useState({ width: 300 });
  const [containerElement, setContainerElement] = useState(null);

  const container = useCallback((node) => {
    if (node !== null) {
      setContainerWidth({ width: node.getBoundingClientRect().width });
      setContainerElement(node);
    }
  }, []);

  const getCustomOptionProps = ({ option, index }) => {
    if (option.disabled) {
      const { key } = getOptionProps({ option, index });
      return { key, className: option.itemType };
    }

    return getOptionProps({ option, index });
  };

  return (
    <div className="autocomplete" ref={container}>
      <div {...getRootProps()}>
        <label
          className="autocomplete__label"
          {...getInputLabelProps()}
        >
          {label}
          {required ? (
            <span className="autocomplete__label--required"> *</span>
          ) : (
            ""
          )}
        </label>
        <div
          className={`autocomplete__input-container ms-Dropdown ${
            errorMessage ? "autocomplete__input-container--error" : ""
          } ${
            disabled ? "autocomplete__input-container--disabled" : ""
          }`}
        >
          <input className="autocomplete__input" {...getInputProps()} disabled={disabled} />
          <span
            className={`autocomplete__icon ${small ? 'autocomplete__icon--small' : ''}`}
            onMouseDown={getInputProps().onMouseDown}
          >
            <Icon iconName="ChevronDown" />
          </span>
        </div>
        {errorMessage ? (
          <div role="alert">
            <p className="autocomplete__input-helper ms-TextField-errorMessage">
              {errorMessage}
            </p>
          </div>
        ) : null}
      </div>
      <Popper
        anchorEl={containerElement}
        open={!!containerElement}
        style={{ zIndex: 10000000 }}
        disablePortal={disablePortal}
      >
        <div>
          {groupedOptions.length > 0 ? (
            <Fade timeout={200} in>
              <ul
                className="autocomplete__listbox"
                style={containerWidth}
                {...getListboxProps()}
              >
                {groupedOptions.map((option, index) => (
                  <li {...getCustomOptionProps({ option, index })}>
                    {multiple &&
                    option.itemType !== "divider" &&
                    option.itemType !== "header" ? (
                      <div
                        className="autocomplete__checkbox"
                        style={
                          option.nestingLevel
                            ? { paddingLeft: option.nestingLevel * 24 }
                            : {}
                        }
                      >
                        <Checkbox
                          checked={
                            getCustomOptionProps({ option, index })[
                              "aria-selected"
                            ]
                          }
                          {...getCustomOptionProps({ option, index })}
                        />
                      </div>
                    ) : null}
                    {option.name || option.text}
                    {showCode && option.code ? <small className="autocomplete__code"><b>Code: {option.code}</b></small>: null}
                  </li>
                ))}
              </ul>
            </Fade>
          ) : null}
        </div>
      </Popper>
    </div>
  );
};

export default Autocomplete;
