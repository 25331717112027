import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  Home,
  RestaurantContainer,
  UsersContainer,
  JobTitlesContainer,
  CountriesContainer,
  CostCentersContainer,
  CompaniesContainer,
  BrandsContainer,
  RestaurantConceptsContainer,
  RestaurantAssetTypesContainer,
  FranchiseBusinessCoachesContainer,
  LogsContainer,
  ReportsContainer
} from "../../";
import { useStateValue } from "../../State/stateProvider";
import PoliciesContainer from "../../Policies/PoliciesContainer";
import NoPermissionPage from "../../NoPermissionPage/NoPermisionPage";
import TranslationsContainer from "../../Translations/TranslationsContainer";

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    <Route {...rest} component={component} />
  ) : (
    <Redirect to="/no-permission" />
  );
};

const Content = () => {
  const [{ auth }] = useStateValue();
  const { groups } = auth;
  const authenticatedFirstLevel = groups.includes("Global");

  const authenticatedSecondLevel =
    authenticatedFirstLevel ||
    groups.includes("Regional") ||
    groups.includes("Franchisee") ||
    groups.includes("Stakeholder");

  const authenticatedThirdLevel =
    authenticatedSecondLevel || groups.includes("Unit");

  return (
    <section className="main__content content">
      <Switch>
        <Route exact path="/" component={Home} />
        <PrivateRoute
          exact
          path="/restaurant/:unit"
          component={RestaurantContainer}
          isAuthenticated={authenticatedThirdLevel}
        />
        <PrivateRoute
          exact
          path="/structure/:structure"
          isAuthenticated={authenticatedThirdLevel}
        />
        <PrivateRoute
          exact
          path="/users/:type"
          component={UsersContainer}
          isAuthenticated={authenticatedSecondLevel}
        />
        <PrivateRoute
          exact
          path="/settings/job-titles"
          component={JobTitlesContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/settings/countries"
          component={CountriesContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/settings/cost-centers"
          component={CostCentersContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/settings/companies"
          component={CompaniesContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/settings/brands"
          component={BrandsContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/settings/restaurant-concepts"
          component={RestaurantConceptsContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/settings/restaurant-asset-types"
          component={RestaurantAssetTypesContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/settings/franchise-business-coaches"
          component={FranchiseBusinessCoachesContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
            exact
            path="/settings/translations"
            component={TranslationsContainer}
            isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/logs/:type"
          component={LogsContainer}
          isAuthenticated={authenticatedFirstLevel}
        />
        <PrivateRoute
          exact
          path="/reports"
          component={ReportsContainer}
          isAuthenticated={authenticatedFirstLevel || groups.includes("Regional")}
        />
        <PrivateRoute
            exact
            path="/policies"
            component={PoliciesContainer}
            isAuthenticated={true}
        />
        <Route path="/no-permission" component={NoPermissionPage} />
        <Route component={Home} />
      </Switch>
    </section>
  );
};

export default Content;
