import React from 'react';
import { useStateValue } from "../State/stateProvider";

const Home = () => {
  const [{ allLabels: { labels } }] = useStateValue();

  return (
    <div>
      {labels.get('home')}
    </div>  
  )
}

export default Home;
