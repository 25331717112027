import { ApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export const getAllReports = async () => {
  try {
    const response = await Client.get_report_list();

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getPeopleReport = async () => {
  try {
    const response = await Client.get_People_Report();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getRestaurantsReport = async () => {
  try {
    const response = await Client.get_Restaurants_Report();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};
