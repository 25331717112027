import React from 'react';
import {
  FontIcon
} from 'office-ui-fabric-react';


const Button = (props) => {
  const { name, icon, className, action } = props;

  return (
    <div className='action__button'>
      <button
        onClick={action}
      >
        <FontIcon 
          iconName={icon}
          className={className}
        />
        <span>{name}</span>
      </button>
    </div>
  )
}

export default Button;