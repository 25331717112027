import React, { useState } from "react";
import { Panel, PanelType, PrimaryButton } from "office-ui-fabric-react";
import { toast } from "react-toastify";

import Button from "./Button";
import Form from "../../../Common/Form";
import { getAllRegions, postRegion } from "../../../Services/RegionService";
import { useStateValue } from "../../../State/stateProvider";
import { getAllStructures } from "../../../Services/StructureService";
import { getStructuresUnits, getAllUnits } from "../../../Services/UnitService";

const AddRegion = () => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const { regionsDictionary, structuresDictionary, brands, countries } = management;

  const [item, setItem] = useState({});

  const openAddPanel = () => setIsCreating(true);
  const dismissAddPanel = () => setIsCreating(false);

  const [isCreating, setIsCreating] = useState(false);

  const confirmChanges = async () => {
    const newRegion = await postRegion({
      structureId: item.structureId,
      parentId: item.parentId,
      name: item.name,
      brandId: item.brandId,
      countryId: item.countryId
    });

    if (newRegion?.data?.id) {
      toast.success(labels.get('region_added'));
      setItem({});

      dispatch({
        type: 'changeLoadingUnits',
        loading: true
      })
      const newStructures = await getAllStructures();
      const newStructuresUnits = await getStructuresUnits();
      const newUnits = await getAllUnits();
      const newRegions = await getAllRegions();

      if (
        newStructures?.data &&
        newStructuresUnits?.data &&
        newUnits?.data &&
        newRegions?.data
      ) {
        dispatch({
          type: "changeStructuresRegionsUnits",
          newStructures: newStructures.data,
          newStructuresUnits: newStructuresUnits.data,
          newUnits: newUnits.data,
          newRegions: newRegions.data,
        });
      }
      dispatch({
        type: 'changeLoadingUnits',
        loading: false
      })

      dismissAddPanel();
    }
  };

  const changeItem = (value, field) => {
    const newItem = {};

    const setField = (field, value) => {
      newItem[field] = value?.id || null;
    };

    switch (field) {
      case "structureId":
        setField("structureId", value);
        if (value?.id && newItem.structureId !== value.id) {
          newItem.parentId = null;
          newItem.parent = null;
        }
        newItem.structure = value || null;
        break;
      case "parentId":
        setField("parentId", value);
        newItem.parent = value || null;
        break;
      case "brandId":
      case "countryId":
        setField(field, value);
        break;
      default:
        break;
    }

    setItem(oldItem => ({ ...oldItem, [field]: value, ...newItem }));
  };

  const onRenderFooterContent = () => (
    <div>
      <PrimaryButton onClick={confirmChanges}>{labels.get('save')}</PrimaryButton>
    </div>
  );

  const fields = [
    {
      type: "column",
      key: "1",
      fields: [
        {
          type: "dropdown",
          label: labels.get('structure'),
          name: "structureId",
          options: structuresDictionary
        },
        {
          type: "dropdown",
          label: labels.get('parent_region'),
          name: "parentId",
          options: regionsDictionary.filter(
            (region) => region.structureId === item.structure?.id
          )
        },
        {
          type: "text",
          label: labels.get('region_name'),
          name: "name",
        },
        {
          type: "dropdown",
          label: labels.get('brand'),
          name: "brandId",
          options: brands,
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('country'),
          name: "countryId",
          options: countries,
          required: true,
        },
      ],
    },
  ];

  return (
    <div>
      <Button
        name={labels.get('add_new_region')}
        icon="Add"
        className="addButton"
        action={openAddPanel}
      />
      <Panel
        headerText={labels.get('add_new_region')}
        type={PanelType.custom}
        customWidth={"28rem"}
        isLightDismiss
        isOpen={isCreating}
        onDismiss={dismissAddPanel}
        closeButtonAriaLabel={labels.get('close')}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Form item={item} changeItem={changeItem} fields={fields} />
      </Panel>
    </div>
  );
};

export default AddRegion;
