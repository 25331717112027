import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Spinner } from "office-ui-fabric-react";

import { useStateValue } from "../State/stateProvider";
import {
  createBrand,
  deleteBrand,
  updateBrand,
  getAllBrands,
} from "../Services/DictionaryService";
import DictionaryList from "./DictionaryList";

const BrandsContainer = () => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const { brands, loadingDictionares } = management;

  const refreshBrands = useCallback(async () => {
    const dictionaryResponse = await getAllBrands();

    if (dictionaryResponse.data) {
      const newBrands = dictionaryResponse.data;

      if (newBrands) {
        dispatch({
          type: "changeBrands",
          newBrands,
        });
      }
    }
  }, [dispatch]);

  useEffect(() => {
    refreshBrands();
  }, [refreshBrands]);

  const handleDelete = async (id) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await deleteBrand(id);

      await refreshBrands();

      if (success) {
        toast.success(labels.get('brand_successfully_deleted'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleEdit = async (id, brand) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await updateBrand(id, brand);

      await refreshBrands();

      if (success) {
        toast.success(labels.get('brand_successfully_updated'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleAdd = async (brand) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await createBrand(brand);

      await refreshBrands();

      if (success) {
        toast.success(labels.get('brand_successfully_added'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  return loadingDictionares ? (
    <div className="center">
      <Spinner className="spinner" label={`${labels.get('processing')}...`} />
    </div>
  ) : (
    <DictionaryList
      dictionary={brands}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onAdd={handleAdd}
      dictionaryName="Brand"
      withManualCodeAddition
      withCodeColumn
    />
  );
};

export default BrandsContainer;
