import { ApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export const getAllBrands = async () => {
  try {
    const response = await Client.get_All_Brands();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const createBrand = async (brand) => {
  try {
    const response = await Client.create_Brand(brand);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const updateBrand = async (id, brand) => {
  try {
    const response = await Client.update_Brand(id, brand);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteBrand = async (id) => {
  try {
    const response = await Client.delete_Brand(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getAllCompanies = async () => {
  try {
    const response = await Client.get_All_Companies();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const createCompany = async (company) => {
  try {
    const response = await Client.create_Company(company);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const updateCompany = async (id, company) => {
  try {
    const response = await Client.update_Company(id, company);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteCompany = async (id) => {
  try {
    const response = await Client.delete_Company(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getAllCostCenters = async () => {
  try {
    const response = await Client.get_All_Cost_Centers();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const createCostCenter = async (costCenter) => {
  try {
    const response = await Client.create_Cost_Center(costCenter);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const updateCostCenter = async (id, costCenter) => {
  try {
    const response = await Client.update_Cost_Center(id, costCenter);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteCostCenter = async (id) => {
  try {
    const response = await Client.delete_Cost_Center(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getAllCountries = async () => {
  try {
    const response = await Client.get_All_Countries();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const createCountry = async (country) => {
  try {
    const response = await Client.create_Country(country);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const updateCountry = async (id, country) => {
  try {
    const response = await Client.update_Country(id, country);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteCountry = async (id) => {
  try {
    const response = await Client.delete_Country(id);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getEmployeeDictionary = async () => {
  try {
    const response = await Client.getEmployeeFormDictionary();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const createJobTitle = async (jobTitle) => {
  try {
    const response = await Client.create_Job_Title(jobTitle);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const updateJobTitle = async (id, jobTitle) => {
  try {
    const response = await Client.update_Job_Title(id, jobTitle);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteJobTitle = async (jobTitle) => {
  try {
    const response = await Client.delete_Job_Title(jobTitle);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const moveUpJobTitle = async (jobId) => {
  try {
    const response = await Client.move_up_job_title(jobId);
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'Something went wrong');
    }
    return false;
  }
};

export const moveDownJobTitle = async (jobId) => {
  try {
    const response = await Client.move_down_job_title(jobId);
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'Something went wrong');
    }
    return false;
  }
};


export const getUnitFormDictionary = async () => {
  try {
    const response = await Client.getUnitFormDictionary();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getAllFranchiseBusinessCoaches = async () => {
  try {
    const response = await Client.get_All_Get_Franchise_business_coaches();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const createFranchiseBusinessCoach = async (franchiseBusinessCoach) => {
  try {
    const response = await Client.create_Franchise_business_coach(franchiseBusinessCoach);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const updateFranchiseBusinessCoach = async (id, franchiseBusinessCoach) => {
  try {
    const response = await Client.update_Franchise_business_coach(id, franchiseBusinessCoach);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteFranchiseBusinessCoach = async (franchiseBusinessCoach) => {
  try {
    const response = await Client.delete_Franchise_business_coach(franchiseBusinessCoach);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getAllRestaurantsAssetTypes = async () => {
  try {
    const response = await Client.get_All_Restaurants_asset_types();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const createRestaurantAssetType = async (restaurantAssetType) => {
  try {
    const response = await Client.create_Restaurant_asset_type(restaurantAssetType);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const updateRestaurantAssetType = async (id, restaurantAssetType) => {
  try {
    return await Client.update_Restaurant_asset_type(id, restaurantAssetType);
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteRestaurantAssetType = async (restaurantAssetType) => {
  try {
    const response = await Client.delete_Restaurant_asset_type(restaurantAssetType);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const getAllRestaurantsConcepts = async () => {
  try {
    const response = await Client.get_All_Restaurants_concepts();

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const createRestaurantConcept = async (restaurantConcept) => {
  try {
    const response = await Client.create_Restaurant_concept(restaurantConcept);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const updateRestaurantConcept = async (id, restaurantConcept) => {
  try {
    return await Client.update_Restaurant_concept(id, restaurantConcept);
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};

export const deleteRestaurantConcept = async (restaurantConcept) => {
  try {
    const response = await Client.delete_Restaurant_concept(restaurantConcept);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};
