import React from 'react';
import './Header.scss';
import { Logo, User } from './HeaderComponents';

const Header = () => {

  return (
    <header className='header'>
      <Logo />
      <div />
      <User />
    </header>
  )
}

export default Header;