import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'office-ui-fabric-react';
import { useStateValue } from '../../../State/stateProvider';

const PoliciesList = () => {
    const [{ allLabels: { labels } }] = useStateValue();
    const history = useHistory();
    const location = useLocation();
    const id = location.pathname;


    const reportsGroup = [
        {
            links: [
                {
                    key: '/policies',
                    name: 'Privacy policies',
                    url: '/policies',
                },
            ],
        },
    ];

    const handleRedirect = (event, element) => {
        event.preventDefault();
        history.replace(element.url);
    };

    return (
        <div className='policies__list'>
            <div className='logs-list__header'>
                <h2>{labels.get('gdpr_policies')}</h2>
            </div>
            <Nav groups={reportsGroup} onLinkClick={handleRedirect} selectedKey={id} />
        </div>
    );
};

export default PoliciesList;
