import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Spinner, Icon } from "office-ui-fabric-react";

import { useStateValue } from "../State/stateProvider";
import {
  createJobTitle,
  deleteJobTitle,
  updateJobTitle,
  getEmployeeDictionary,
  moveUpJobTitle,
  moveDownJobTitle
} from "../Services/DictionaryService";
import DictionaryList from "./DictionaryList";

const JobTitlesContainer = () => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const { jobTitles, loadingDictionares } = management;

  const refreshJobTitles = useCallback(async () => {
    const dictionaryResponse = await getEmployeeDictionary();

    if (dictionaryResponse.data) {
      const newJobTitles = dictionaryResponse.data.employeeJobTitle;
      const newEmployeeTypes = dictionaryResponse.data.employeeType;

      dispatch({
        type: "changeJobTitles",
        newJobTitles,
      });

      dispatch({
        type: "changeEmployeeTypes",
        newEmployeeTypes,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    refreshJobTitles();
  }, [refreshJobTitles]);

  const handleDelete = async (key) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await deleteJobTitle(key);

      await refreshJobTitles();

      if (success) {
        toast.success(labels.get('job_title_successfully_deleted'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleEdit = async (key, text) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await updateJobTitle(key, text);

      await refreshJobTitles();
      if (success) {
        toast.success(labels.get('job_title_successfully_updated'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleAdd = async (text) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await createJobTitle(text);

      await refreshJobTitles();
      if (success) {
        toast.success(labels.get('job_title_successfully_updated'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleMoveUp = async (text) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await moveUpJobTitle(text);

      await refreshJobTitles();
      if (success) {
        toast.success(labels.get('job_title_has_been_successfully_moved_up'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleMoveDown = async (text) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await moveDownJobTitle(text);

      await refreshJobTitles();
      if (success) {
        toast.success(labels.get('job_title_has_been_successfully_moved_down'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  return loadingDictionares ? (
    <div className="center">
      <Spinner className="spinner" label={`${labels.get('processing')}...`} />
    </div>
  ) : (
    <DictionaryList
      dictionary={jobTitles}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onAdd={handleAdd}
      onMoveUp={handleMoveUp}
      onMoveDown={handleMoveDown}
      dictionaryName="Job Title"
    />
  );
};

export default JobTitlesContainer;
