import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Spinner } from "office-ui-fabric-react";

import { useStateValue } from "../State/stateProvider";
import {
    createCostCenter,
    deleteCostCenter,
    updateCostCenter,
    getAllCostCenters,
} from "../Services/DictionaryService";
import DictionaryList from "./DictionaryList";

const CostCentersContainer = () => {
    const [{management, allLabels: {labels}}, dispatch] = useStateValue();
    const {costCenters, loadingDictionares} = management;

    const refreshCostCenters = useCallback(async () => {
        const dictionaryResponse = await getAllCostCenters();

        if (dictionaryResponse.data) {
            const newCostCenters = dictionaryResponse.data;

            if (newCostCenters) {
                dispatch({
                    type: "changeCostCenters",
                    newCostCenters,
                });
            }
        }
    }, [dispatch]);

    useEffect(() => {
        refreshCostCenters();
    }, [refreshCostCenters]);

    const handleDelete = async (id) => {
        dispatch({
            type: "changeLoadingDictionares",
            loading: true,
        });
        try {
            const success = await deleteCostCenter(id);

            await refreshCostCenters();

            if (success) {
                toast.success(labels.get('costs_center_successfully_deleted'));
            }
        } catch (e) {
            console.log(e);
        }
        dispatch({
            type: "changeLoadingDictionares",
            loading: false,
        });
    };

    const handleEdit = async (id, costCenter) => {
        if (costCenter.brandId === '0') {
            costCenter.brandId = costCenter.brand.id;
        }

        if (costCenter.country && costCenter.country.id) {
            costCenter.countryId = costCenter.country.id;
        }

        dispatch({
            type: "changeLoadingDictionares",
            loading: true,
        });
        try {
            const success = await updateCostCenter(id, costCenter);

            await refreshCostCenters();

            if (success) {
                toast.success(labels.get('costs_center_successfully_updated'));
            }
        } catch (e) {
            console.log(e);
        }
        dispatch({
            type: "changeLoadingDictionares",
            loading: false,
        });
    };

    const handleAdd = async (costCenter) => {
        dispatch({
            type: "changeLoadingDictionares",
            loading: true,
        });
        try {
            const success = await createCostCenter(costCenter);

            await refreshCostCenters();

            if (success) {
                toast.success(labels.get('costs_center_successfully_added'));
            }
        } catch (e) {
            console.log(e);
        }
        dispatch({
            type: "changeLoadingDictionares",
            loading: false,
        });
    };

    return loadingDictionares ? (
        <div className="center">
            <Spinner className="spinner" label={ `${ labels.get('processing') }...` }/>
        </div>
    ) : (
        <DictionaryList
            dictionary={ costCenters }
            onEdit={ handleEdit }
            onDelete={ handleDelete }
            onAdd={ handleAdd }
            dictionaryName={ labels.get('cost_center') }
            withManualCodeAddition
            withCodeColumn
            withEmail
            withBrand
            withCountry
            withMailboxStatus
            disableCodeEditing
        />
    );
};

export default CostCentersContainer;
