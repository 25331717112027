import { ApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export const getLicenses = async () => {
  try {
    if(Client) {
      const response = await Client.calculations();
      return response;
    }
    else {
      return false;
    }
  } catch (error) {
      console.log('ERROR', error);
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};