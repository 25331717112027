import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from "react";
import {
  Panel,
  PanelType,
  DefaultButton,
  PrimaryButton,
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react";
import { useStateValue } from "../../../State/stateProvider";
import {
  putStructure,
  deleteStructure,
  getAllStructures,
} from "../../../Services/StructureService";
import { getStructuresUnits, getAllUnits } from "../../../Services/UnitService";
import { getAllRegions } from "../../../Services/RegionService";
import Form from "../../../Common/Form";

const EditStructure = forwardRef((props, ref) => {
  const [{allLabels: { labels }}, dispatch] = useStateValue();

  const [item, setItem] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const openPanel = () => setIsEditing(true);
  const dismissPanel = () => setIsEditing(false);

  const changeItem = (value, field) => {
    setItem((oldItem) => {
      return {
        ...oldItem,
        [field]: value,
      };
    });
  };

  const confirm = useCallback(async () => {
    const request = await putStructure(item.id, item);

    if (request?.data?.id) {
      dispatch({
        type: 'changeLoadingUnits',
        loading: true
      })
      const newStructures = await getAllStructures();
      const newStructuresUnits = await getStructuresUnits();
      const newUnits = await getAllUnits();
      const newRegions = await getAllRegions();

      if (
        newStructures?.data &&
        newStructuresUnits?.data &&
        newUnits?.data &&
        newRegions?.data
      ) {
        dispatch({
          type: "changeStructuresRegionsUnits",
          newStructures: newStructures.data,
          newStructuresUnits: newStructuresUnits.data,
          newUnits: newUnits.data,
          newRegions: newRegions.data,
        });
      }

      dispatch({
        type: 'changeLoadingUnits',
        loading: false
      })

      setItem({});
      setIsEditing(false);
    }
  }, [item, dispatch]);

  useImperativeHandle(ref, () => ({
    editEntity(entity) {
      setItem({
        id: entity.id,
        name: entity.name,
        code: entity.code,
      });
      openPanel();
    },
  }));

  const fields = useMemo(
    () => [
      {
        type: "column",
        key: "1",
        fields: [
          {
            type: "text",
            label: labels.get('structure_name'),
            name: "name",
          },
          {
            type: "text",
            label: labels.get('structure_code'),
            name: "code",
            disabled: true,
          },
        ],
      },
    ],
    []
  );

  const [dialog, setDialog] = useState(false);

  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => setDialog(false);

  const confirmDialog = useCallback(async () => {
    const response = await deleteStructure(item.id);
    setDialog(false);

    if (response) {
      setItem({});
      setIsEditing(false);
      dispatch({
        type: 'changeLoadingUnits',
        loading: true
      })

      const newStructures = await getAllStructures();
      const newStructuresUnits = await getStructuresUnits();
      const newUnits = await getAllUnits();
      const newRegions = await getAllRegions();

      if (
        newStructures?.data &&
        newStructuresUnits?.data &&
        newUnits?.data &&
        newRegions?.data
      ) {
        dispatch({
          type: "changeStructuresRegionsUnits",
          newStructures: newStructures.data,
          newStructuresUnits: newStructuresUnits.data,
          newUnits: newUnits.data,
          newRegions: newRegions.data,
        });
      }

      dispatch({
        type: 'changeLoadingUnits',
        loading: false
      })
    }
  }, [item, dispatch]);

  const onRenderFooterContent = useCallback(
    () => (
      <div style={{ display: "flex", gap: "12px" }}>
        <PrimaryButton onClick={confirm}>Save changes</PrimaryButton>
        <DefaultButton onClick={openDialog}>Delete structure</DefaultButton>
        <Dialog
          hidden={!dialog}
          onDismiss={closeDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: labels.get('delete_structure'),
          }}
        >
          <p>{labels.get('are_you_sure_you_want_to_delete')} {item.name}?</p>
          <DialogFooter>
            <PrimaryButton onClick={confirmDialog} text={labels.get('delete')} />
            <DefaultButton onClick={closeDialog} text={labels.get('cancel')} />
          </DialogFooter>
        </Dialog>
      </div>
    ),
    [confirm, confirmDialog, dialog, item.name]
  );

  return (
    <Panel
      headerText={labels.get('edit_structure')}
      type={PanelType.custom}
      customWidth="28rem"
      isLightDismiss
      isOpen={isEditing}
      onDismiss={dismissPanel}
      closeButtonAriaLabel={labels.get('close')}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      <Form
        item={item}
        changeItem={changeItem}
        fields={fields}
      />
    </Panel>
  );
});

export default EditStructure;
