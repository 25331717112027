import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStateValue } from '../components/State/stateProvider';
import {
  getGdprFiles,
  getFileContent,
  getFileContentMetadata,
} from "../components/Services/GdprService";
import { Icon } from "office-ui-fabric-react";
import { Logo } from "../components/Header/HeaderComponents";
import "./Gdpr.scss";
import "../components/Header/Header.scss";

export const GdprContainer = ({ location }) => {
  const [{ allLabels: { labels } }] = useStateValue();

  const [items, setItems] = useState([]);
  const currentFolder = location.pathname.replace('/gdpr', '') || '.';
  
  document.title = `AmRest CFDB - ${labels.get('gdpr_portal')}`;
  
  useEffect(() => {
    getGdprFiles(currentFolder)
      .then((results) => setItems(results.data))
      .catch((e) => console.error(e.message));
  }, [currentFolder]);

  const renderHeader = () => (
    <header className="header">
      <Logo />
    </header>
  );

  const renderItems = () => {
    return (
      <div>
        {
          items.map((item, key) => {
            if(item.itemName === '..') return;

            const { itemType, itemName, path, fullURL } = item;
            const link = itemType === 'folder' ? `/gdpr/${path}` : fullURL;
            const ext = (/\.(\w)+$/i).exec(itemName)?.shift();
            let icon = '';

            switch(itemType) {
              case 'folder':
                icon = 'FabricFolder';
                break;
              case 'file':
                switch(ext) {
                  case '.pdf':
                    icon = 'PDF';
                    break;
                  case '.doc':
                  case '.docx':
                    icon = 'WordDocument';
                    break;
                }
                break;
            }

            return (
              <div className="gdpr-item" key={key}>
                <Icon iconName={icon} className='gdpr-icon' />
                <Link 
                  to={{ pathname: link }} 
                  target={itemType === "file" ? "_blank" : ""}>
                    {itemName}
                </Link>
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <div>
      {renderHeader()}
      <div className="gdpr-content">
        <h2>{labels.get('gdpr_policies')}</h2>
        {renderItems()}
      </div>
    </div>
  );
};
