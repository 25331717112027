export const labelsReducer = (state, action) => {
    switch (action.type) {
      case 'changeLabels':
        return {
          ...state,
          labels: action.newLabels
        };
      default:
        return state;
    }
  };
  
  export const labelsReducerInitialState = {
    labels: new Map(),
  };