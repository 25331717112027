import React from 'react';
import { AddEmployee, AddUnit, AddStructure, AddUser, AddRegion } from './ActionComponents';
import { useStateValue } from '../../State/stateProvider';

import './Action.scss';

const Action = () => {
  const [{ auth }] = useStateValue();
  const { groups } = auth;

  return (
    <div className="main__action action">
      { groups.includes("Unit") || groups.includes("Regional") || groups.includes("Franchisee") || groups.includes("Global") ? <AddEmployee /> : null}
      { groups.includes("Global") ? <AddStructure /> : null}
      { groups.includes("Global") || groups.includes("Franchisee") ? <AddRegion /> : null}
      { groups.includes("Global") || groups.includes("Regional") ? <AddUnit /> : null}
      { groups.includes("Global") ? <AddUser /> : null}
    </div>
  )
}

export default Action;