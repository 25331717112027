import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'office-ui-fabric-react';
import { useStateValue } from "../../../State/stateProvider";

const UsersList = ({ groups }) => {
  const [{ allLabels: { labels } }] = useStateValue();
  const history = useHistory();
  const location = useLocation();
  const id = location.pathname;

  const handleRedirect = (event, element) => {
    event.preventDefault();
    history.replace(element.url);
  };

  const globalGroups = [
    {
      links: [
        {
          key: '/users/all',
          name: labels.get('all'),
          url: '/users/all'
        },
        {
          key: '/users/assigned',
          name: labels.get('assigned'),
          url: '/users/assigned'
        },
        {
          key: '/users/unassigned',
          name: labels.get('unassigned'),
          url: '/users/unassigned'
        },
        {
          key: '/users/restaurantAdmins',
          name: labels.get('restaurant_admins'),
          url: '/users/restaurantAdmins'
        },
      ],
    },
  ];
  
  const regionalGroups = [
    {
      links: [
        {
          key: '/users/restaurantAdmins',
          name: labels.get('restaurant_admins'),
          url: '/users/restaurantAdmins'
        },
        {
          key: '/users/unassigned',
          name: labels.get('unassigned'),
          url: '/users/unassigned'
        },
      ],
    },
  ]
  
  const localGroups = [
    {
      links: [
        {
          key: '/users/restaurantAdmins',
          name: labels.get('restaurant_admins'),
          url: '/users/restaurantAdmins'
        },
        {
          key: '/users/unassigned',
          name: labels.get('unassigned'),
          url: '/users/unassigned'
        },
      ],
    },
  ]
  
  const getNavGroups = (userGroups) => {
    if (userGroups.includes("Global")) {
      return globalGroups;
    }
  
    if (userGroups.includes("Franchisee")) {
      return localGroups;
    }
  
    return regionalGroups;
  }

  return (
    <div className='users-list'>
      <div className='users-list__header'>
        <h2>{labels.get('users')}</h2>
      </div>
      <Nav
        groups={getNavGroups(groups)}
        onLinkClick={handleRedirect}
        selectedKey={id}
        onRenderLink={(link) => link.name}
      />
    </div>
  );
};

export default UsersList;
