import { ApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export class Labels {
  constructor(labels) {
    this.labels = new Map(labels);
  }

  get = (label) => {
    if(this.labels.has(label)) {
      return this.labels.get(label);
    } else {
      return label;
    }
  }
}

export const getAllLabels = async (language = null) => {
  try {
    if(Client) {
      const response = await Client.labels(language);
      return response;
    }
    else {
      return false;
    }
  } catch (error) {
      console.log('ERROR', error);
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('toast_error', 'something_went_wrong');
    }
    return false;
  }
};
