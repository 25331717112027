import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Spinner } from "office-ui-fabric-react";

import { useStateValue } from "../State/stateProvider";
import {
  createCountry,
  deleteCountry,
  updateCountry,
  getAllCountries,
} from "../Services/DictionaryService";
import DictionaryList from "./DictionaryList";

const CountriesContainer = () => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const { countries, loadingDictionares } = management;

  const refreshCountries = useCallback(async () => {
    const dictionaryResponse = await getAllCountries();

    if (dictionaryResponse.data) {
      const newCountries = dictionaryResponse.data;

      if (newCountries) {
        dispatch({
          type: "changeCountries",
          newCountries,
        });
      }
    }
  }, [dispatch]);

  useEffect(() => {
    refreshCountries();
  }, [refreshCountries]);

  const handleDelete = async (id) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await deleteCountry(id);

      await refreshCountries();

      if (success) {
        toast.success(labels.get('country_successfully_deleted'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleEdit = async (id, country) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await updateCountry(id, country);

      await refreshCountries();

      if (success) {
        toast.success(labels.get('country_successfully_updated'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  const handleAdd = async (country) => {
    dispatch({
      type: "changeLoadingDictionares",
      loading: true,
    });
    try {
      const success = await createCountry(country);

      await refreshCountries();

      if (success) {
        toast.success(labels.get('country_successfully_added'));
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "changeLoadingDictionares",
      loading: false,
    });
  };

  return loadingDictionares ? (
    <div className="center">
      <Spinner className="spinner" label={`${labels.get('processing')}...`} />
    </div>
  ) : (
    <DictionaryList
      dictionary={countries}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onAdd={handleAdd}
      dictionaryName={labels.get('country')}
      withManualCodeAddition
      withCodeColumn
    />
  );
};

export default CountriesContainer;
