import { UnAuthApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";
import { emitter } from "../../App";

export const getGdprFiles = async (path) => {
  try {
    const response = await Client.items(path);

    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      emitter.emit('something_went_wrong');
    }
    return false;
  }
};

export const getFileContent = async (path) => {
    try {
      const response = await Client.get_file_content(path);
  
      return response;
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        handleLogout();
      } else if (error?.errors) {
        error.errors.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        emitter.emit('something_went_wrong');
      }
      return false;
    }
  };

  export const getFileContentMetadata = async (path) => {
    try {
      const response = await Client.get_file_content_metadata(path);
  
      return response;
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        handleLogout();
      } else if (error?.errors) {
        error.errors.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        emitter.emit('something_went_wrong');
      }
      return false;
    }
  };