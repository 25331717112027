import React, { useState } from "react";
import { Panel, PanelType, PrimaryButton } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import Button from "./Button";
import Form from "../../../Common/Form";
import { inviteNewUser } from "../../../Services/UserService";
import { useStateValue } from "../../../State/stateProvider";

// eslint-disable-next-line no-useless-escape
const emailTester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@(amrest.eu|angrynerds.co|angrynerds.pl)$/;

const AddUser = () => {
  const [{allLabels: { labels }}, dispatch] = useStateValue();

  const [newItem, setNewItem] = useState({});

  const openAddPanel = () => setIsCreating(true);
  const dismissAddPanel = () => setIsCreating(false);

  const [isCreating, setIsCreating] = useState(false);

  const groups = [
    { key: "Global", text: labels.get('global') },
    { key: "Franchisee", text: labels.get('franchisee') },
    { key: "Regional", text: labels.get('regional') },
    { key: "Stakeholder", text: labels.get('stakeholder') },
    { key: "Unit", text: labels.get('unit') },
  ];

  const confirmChanges = async () => {
    const error = Object.entries(newItem).find(([key, value]) => {
      if (key === "email") {
        if (value && !emailTester.test(value)) {
          return true;
        }
      }

      return false;
    });

    const requiredDataMissing =
      !newItem.firstName ||
      !newItem.surname ||
      !newItem.email ||
      !newItem.groupType;

    if (!error && !requiredDataMissing) {
      const newUser = await inviteNewUser(newItem);
      if (newUser?.data?.id) {
        toast.success(
          `${newItem.firstName} ${newItem.surname} ${labels.get('has_been_successfully_added')}!`
        );
        setNewItem({});
        dismissAddPanel();
        dispatch({
          type: "changeRefreshData",
        });
      }
    } else {
      if (error) {
        toast.error(labels.get('correct_form_errors'));
      }
      if (requiredDataMissing) {
        toast.error(labels.get('fill_required_fields'));
      }
    }
  };

  const changeItem = (value, field) => {
    if (field === "groupType") {
      setNewItem((oldItem) => {
        return {
          ...oldItem,
          [field]: value?.key,
        };
      });
    } else {
      setNewItem((oldItem) => {
        return {
          ...oldItem,
          [field]: value,
        };
      });
    }
    
  };

  const onRenderFooterContent = () => (
    <div>
      <PrimaryButton onClick={confirmChanges}>{labels.get('save')}</PrimaryButton>
    </div>
  );

  const fields = [
    {
      type: "column",
      key: "1",
      fields: [
        {
          type: "email",
          label: "AmRest e-mail",
          name: "email",
          required: true,
        },
        {
          type: "text",
          label: labels.get('first_name'),
          name: "firstName",
          required: true,
        },
        {
          type: "text",
          label: labels.get('surname'),
          name: "surname",
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('group'),
          name: "groupType",
          options: groups,
          required: true,
        },
      ],
    },
  ];

  return (
    <div>
      <Button
        name={labels.get('add_new_user')}
        icon="Add"
        className="addButton"
        action={openAddPanel}
      />
      <Panel
        headerText={labels.get('add_new_user')}
        type={PanelType.custom}
        customWidth={"28rem"}
        isLightDismiss
        isOpen={isCreating}
        onDismiss={dismissAddPanel}
        closeButtonAriaLabel={labels.get('close')}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Form item={newItem} changeItem={changeItem} fields={fields} />
      </Panel>
    </div>
  );
};

export default AddUser;
