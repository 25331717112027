import React, { useState, useEffect, useCallback, useRef } from "react";
import { Spinner } from "office-ui-fabric-react";
import { useParams } from "react-router-dom";

import { useStateValue } from "../State/stateProvider";
import { getLogs } from "../Services/LogsService";
import useDebounce from "../Common/useDebounce";
import Logs from "./Logs";

import "./Logs.scss";

const LogsContainer = () => {
  const [{ allLabels: { labels } }] = useStateValue();
  const { type } = useParams();
  const [logs, setLogs] = useState(null);
  const [sortedColumnKey, setSortedColumnKey] = useState(null);
  const [sortedDescending, setSortedDescending] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const mounted = useRef(null);

  const fetchData = useCallback(
    async (onlyResponse = false, passedPageNumber = 0) => {
      const newLogsResponse = await getLogs({
        search: debouncedSearchValue,
        pageNumber: passedPageNumber * 100,
        pageSize: 100,
        logType: type === "failure" ? "error" : undefined,
      });

      if (!mounted.current) {
        return;
      }

      if (!newLogsResponse?.data) {
        setLogs([]);
        setLoadingData(false);
        return;
      }

      if (!newLogsResponse?.data?.length) {
        if (!logs) {
          setLogs([]);
        }
        if (pageNumber) {
          setPageNumber(oldPageNumber => oldPageNumber - 1);
        }
        setLoadingData(false);
        return;
      }

      const newLogs = newLogsResponse.data;

      const oldLogs = [];
      if (logs && !onlyResponse) {
        oldLogs.push(...logs);
      }

      if (sortedColumnKey) {
        setLogs(
          [...oldLogs, ...newLogs]
            .slice()
            .sort(
              (a, b) =>
                ((a[sortedColumnKey] || "") < (b[sortedColumnKey] || "")
                  ? 1
                  : -1) * sortedDescending
            )
        );
      } else {
        setLogs([...oldLogs, ...newLogs]);
      }

      setLoadingData(false);
    },
    [logs, pageNumber, type, debouncedSearchValue, sortedColumnKey, sortedDescending]
  );

  const updateLogs = (columnKey, isSortedDescending) => {
    setSortedColumnKey(columnKey);
    setSortedDescending(isSortedDescending);
    const newLogs = [];
    const sortDirection = isSortedDescending ? 1 : -1;
    newLogs.push(
      ...logs
        .slice()
        .sort(
          (a, b) =>
            ((a[columnKey] || "") < (b[columnKey] || "") ? 1 : -1) *
            sortDirection
        )
    );

    setLogs(newLogs);
  };

  const changeSearchValue = (value) => {
    setSearchValue(value);
    setPageNumber(0);
  };

  const handleLoadMore = () => {
    if (logs && logs.length && logs.length % 100 === 0) {
      setLoadingData(true);
      const newPageNumber = pageNumber + 1;
      setPageNumber(newPageNumber);
      fetchData(false, newPageNumber);
    }
  };

  useEffect(() => {
    setLogs(null);
    setLoadingData(true);
    fetchData(true);
  }, [debouncedSearchValue]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <>
      {logs ? (
        <Logs
          sortedColumnKey={sortedColumnKey}
          isSortedDescending={sortedDescending}
          changeSearchValue={changeSearchValue}
          handleLoadMore={handleLoadMore}
          searchValue={searchValue}
          updateLogs={updateLogs}
          logs={logs}
          type={type}
          loadingData={loadingData}
        />
      ) : (
        <div className="center">
          <Spinner className="spinner" label={`${labels.get('loading_logs')}...`} />
        </div>
      )}
    </>
  );
};

export default LogsContainer;
