import React, { useRef, useState, useMemo, useEffect } from "react";
import {
  FontIcon,
  ContextualMenu,
  PrimaryButton,
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
} from "office-ui-fabric-react";
import {
  postAssignUserToRegion,
  postAssignUserToUnit,
  postAssignUserToGroup,
  revokeUserAccess,
} from "../Services/UserService";
import { useStateValue } from "../State/stateProvider";
import Autocomplete from "../Common/Autocomplete";

import "./Users.scss";

const UserAction = (props) => {
  const [{ auth, management, allLabels: { labels } }, dispatch] = useStateValue();
  const { regionsDictionary, unitsDictionary, structuresDictionary } =
    management;
  const { groups } = auth;

  const { item, type } = props;
  const linkRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showContextualMenu, setShowContextualMenu] = useState(false);
  const onShowContextualMenu = () => setShowContextualMenu(true);
  const onHideContextualMenu = () => setShowContextualMenu(false);

  const [dialog, setDialog] = useState(true);
  const [dialogType, setDialogType] = useState();
  const [typeText, setTypeText] = useState();

  const itemGroupId = item.group?.id;

  const items = useMemo(() => {
    const unitItems = {
      key: "assignUserToUnit",
      text: labels.get('assign_user_to_restaurant'),
      onClick: () =>
        openDialog("assignUserToUnit", labels.get('assign_user_to_restaurant')),
    };

    const unassignedItems = {
      key: "assignUserToGroup",
      text: labels.get('assign_user_to_group'),
      onClick: () => openDialog("assignUserToGroup", labels.get('assign_user_to_group')),
    };

    const assignedItems = {
      key: "assignUserToRegion",
      text: labels.get('assign_user_to_region'),
      onClick: () => openDialog("assignUserToRegion", labels.get('assign_user_to_region')),
    };

    const revokeItems = {
      key: "revokeAdminAccess",
      text: labels.get('revoke_admin_access'),
      onClick: () => openDialog("revokeAdminAccess", labels.get('revoke_admin_access')),
    };

    const newItems = [];
    
    if (
      type === "assigned" &&
      (item.groupName === "Regional" || item.groupName === "Franchisee" || item.groupName === "Stakeholder") &&
      (groups.includes("Global") || groups.includes("Franchisee"))
    ) {
      newItems.push(assignedItems);
    }

    if (groups.includes("Global")) {
      const adminGroups = [
        process.env.REACT_APP_GROUP_GLOBAL,
        process.env.REACT_APP_GROUP_REGIONAL,
        process.env.REACT_APP_GROUP_STAKEHOLDER,
        process.env.REACT_APP_GROUP_LOCAL,
        process.env.REACT_APP_GROUP_UNIT,
      ];
      if (
        type === "assigned" ||
        (type === "all" && adminGroups.includes(itemGroupId))
      ) {
        newItems.push(revokeItems);
      }
    }

    if (type === "unassigned" && groups.includes("Global")) {
      newItems.push(unassignedItems);
    }

    if (type === "unassigned" && groups.includes("Regional")) {
      newItems.push(
        {
          key: "assignUserToGroup",
          text: labels.get('assign_user_to_unit_admin'),
          onClick: async () => (
            postAssignUserToGroup({
              groupType: "unit",
              userInDomainId: item.userId,
            }).then(
              dispatch({
                type: "changeRefreshData",
              })
            )
          ),
        }
      );
    }

    if ((type === "restaurantAdmins" || itemGroupId === process.env.REACT_APP_GROUP_UNIT) && (groups.includes("Global") || groups.includes("Regional") || groups.includes("Franchisee"))) {
      newItems.push(unitItems);
    }

    if (newItems.length) {
      return newItems;
    }

    return false;
  }, [type, item.groupName, itemGroupId, groups]);

  const openDialog = (newType, typeText) => {
    setDialogType(newType);
    setTypeText(typeText);
    setDialog(false);
  };

  const closeDialog = () => {
    setDialog(true);
    setSelectedItems([]);
  };

  const confirmDialog = async (newType) => {
    try {
      if (newType === "assignUserToUnit") {
        await postAssignUserToUnit({
          unitIds: selectedItems,
          userId: item.id,
        });
      }

      if (newType === "assignUserToRegion") {
        await postAssignUserToRegion({
          regionIds: selectedItems,
          userId: item.id,
          action: "add",
        });
      }

      if (newType === "assignUserToGroup") {
        await selectedItems.reduce(async (previousItem, selectedItem) => {
          await previousItem;
          await postAssignUserToGroup({
            groupType: selectedItem,
            userInDomainId: item.userId,
          });
        }, Promise.resolve());
      }

      if (newType === "revokeAdminAccess") {
        await revokeUserAccess({ userId: item.id });
      }

      setDialog(true);
      dispatch({
        type: "changeRefreshData",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDropdownChange = (_, changedItems) => {
    const newSelectedItems = changedItems.map(entry => entry instanceof Object ? entry.key : entry);

    if (dialogType === "assignUserToRegion") {
      const removedItems = selectedItems.filter(
        (entry) => !changedItems.includes(entry)
      );
      const addedItems = newSelectedItems
        .filter((entry) => !selectedItems.includes(entry));

      if (addedItems.length) {
        addedItems.forEach((addedItem) => {
          const childRegions = regionsDictionary
            .filter((region) => region.parentIds.includes(addedItem))
            .map((region) => region.key);
          childRegions.forEach((region) => {
            if (!newSelectedItems.includes(region)) {
              newSelectedItems.push(region);
            }
          });
        });
      }

      if (removedItems.length) {
        removedItems.forEach((removedItem) => {
          const childRegions = regionsDictionary
            .filter((region) => region.parentIds.includes(removedItem))
            .map((region) => region.key);

          childRegions.forEach((region) => {
            const parentIndex = newSelectedItems.indexOf(region);
            if (parentIndex > -1) {
              newSelectedItems.splice(parentIndex, 1);
            }
          });
        });
      }
    }

    setSelectedItems(newSelectedItems);
  };

  const getDictionary = useMemo(() => {
    if (dialogType === "assignUserToUnit") {
      return unitsDictionary;
      // return unitsDictionary.map(unit => {
      //   if (unitIds.includes(unit.key)) {
      //     return { ...unit, disabled: true };
      //   }

      //   return unit;
      // });
    }
    if (dialogType === "assignUserToRegion") {
      // return regionsDictionary;
      // return regionsDictionary.map(region => {
      //   if (regionIds.includes(region.key)) {
      //     return { ...region, disabled: true };
      //   }

      //   return region;
      // });
      const newValues = [];

      regionsDictionary.forEach((region) => {
        const structureName = !region.parentId
          ? ` (${
              structuresDictionary.find(
                (structure) => structure.key === region.structureId
              )?.text || ""
            })`
          : "";

        const option = {
          ...region,
          name: `${region.text}${structureName}`,
          nestingLevel: region.parentIds.length,
          // name: `${"≫ ".repeat(region.parentIds.length)}${
          //   region.text
          // }${structureName}`,
        };

        if (region.parentIds.length === 0) {
          const pureStructureName = !region.parentId
            ? `${
                structuresDictionary.find(
                  (structure) => structure.key === region.structureId
                )?.text || ""
              }`
            : "";
          if (newValues.length !== 0) {
            newValues.push({
              key: `divider-${pureStructureName}-${region.structureId}`,
              name: "",
              disabled: true,
              itemType: "divider",
            });
          }
          newValues.push({
            key: `header-${pureStructureName}-${region.structureId}`,
            name: pureStructureName,
            disabled: true,
            itemType: "header",
          });
        }

        newValues.push(option);
      });

      return newValues;
    }

    if (dialogType === "assignUserToGroup") {
      return [
        {
          key: "global",
          text: labels.get('global'),
        },
        {
          key: "franchisee",
          text: labels.get('franchisee'),
        },
        {
          key: "regional",
          text: labels.get('regional'),
        },
        {
          key: "stakeholder",
          text: labels.get('stakeholder'),
        },
        {
          key: "unit",
          text: labels.get('unit'),
        },
      ];
    }
  }, [dialogType, unitsDictionary, regionsDictionary, structuresDictionary]);

  useEffect(() => {
    if (dialogType) {
      if (dialogType === "assignUserToUnit") {
        const unitIds =
          item.userUnits?.map((unit) => unit?.key || unit?.id) || [];
        setSelectedItems(
          unitsDictionary
            .filter((unit) => unitIds.includes(unit.key))
            .map((unit) => unit.key)
        );
      } else if (dialogType === "assignUserToRegion") {
        const regionIds =
          item.userRegions?.map((region) => region?.id || region?.key) || [];
        setSelectedItems(
          regionsDictionary
            .filter((region) => regionIds.includes(region.key))
            .map((region) => region.key)
        );
      } else {
        setSelectedItems([]);
      }
    }
  }, [dialog, dialogType, unitsDictionary, regionsDictionary, item]);

  const handleSelectAll = () => {
    const newSelectedItems = [];
    getDictionary.forEach((item) => {
      if (!item.key.includes("divider-") && !item.key.includes("header-")) {
        newSelectedItems.push(item.key);
      }
    });
    setSelectedItems(newSelectedItems);
  };

  const handleDeselectAll = () => {
    const newSelectedItems = [];
    setSelectedItems(newSelectedItems);
  };

  const label = (
    <span>
      {labels.get('selected')} {selectedItems.length}{" "}
      {dialogType === "assignUserToRegion" ? "region" : ""}
      {dialogType === "assignUserToUnit" ? "restaurant" : ""}
      {dialogType === "assignUserToGroup" ? "group" : ""}
      {selectedItems.length !== 1 ? "s" : ""}
    </span>
  );

  return (
    <>
      {items ? (
        <div className={`user-action ${showContextualMenu ? "active" : ""}`}>
          <button ref={linkRef} onClick={onShowContextualMenu}>
            <FontIcon iconName="MoreVertical" className="moreButton" />
          </button>
          <ContextualMenu
            items={items}
            hidden={!showContextualMenu}
            target={linkRef}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
          />
          <Dialog
            hidden={dialog}
            onDismiss={closeDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: `${typeText}`,
            }}
          >
            {dialogType !== "revokeAdminAccess" ? (
              <div className="user-action__buttons">
                <DefaultButton onClick={handleSelectAll} text={labels.get('select_all')} />
                <DefaultButton
                  onClick={handleDeselectAll}
                  text={labels.get('deselect_all')}
                />
              </div>
            ) : null}
            {dialogType !== "revokeAdminAccess" ? (
              <Autocomplete
                placeholder={labels.get('select')}
                value={selectedItems}
                onChange={onDropdownChange}
                multiple
                options={getDictionary}
                label={label}
                disableCloseOnSelect
              />
            ) : null}
            {dialogType === "revokeAdminAccess" ? (
              <span>
                After operation user {item.firstName} {item.lastName} will not
                be able to log in into CFDB system. Do you want to proceed?
              </span>
            ) : null}
            <DialogFooter>
              <PrimaryButton
                onClick={() => confirmDialog(dialogType)}
                text={labels.get('confirm')}
              />
              <DefaultButton onClick={closeDialog} text={labels.get('cancel')} />
            </DialogFooter>
          </Dialog>
        </div>
      ) : null}
    </>
  );
};

export default UserAction;
