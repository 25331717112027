import React from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Sticky,
  StickyPositionType,
  ScrollbarVisibility,
  ConstrainMode,
  ScrollablePane,
  TextField,
  TooltipHost,
  SelectionMode,
  PrimaryButton,
  Spinner,
} from "office-ui-fabric-react";
import moment from "moment";
import { useStateValue } from "../State/stateProvider";
import useWindowSize from "../Common/useWindowSize";

import "./Logs.scss";

const Logs = ({
  sortedColumnKey,
  isSortedDescending,
  changeSearchValue,
  handleLoadMore,
  searchValue,
  updateLogs,
  logs,
  type,
  loadingData,
}) => {
  const [{ allLabels: { labels } }] = useStateValue();
  const size = useWindowSize();

  const onColumnClick = (column) => {
    updateLogs(column.fieldName, !column.isSortedDescending);
  };

  const columns = [
    {
      key: "created",
      name: "Created Date",
      fieldName: "created",
      minWidth: 110,
      maxWidth: 110,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: labels.get('sorted_az'),
      sortDescendingAriaLabel: labels.get('sorted_za'),
      onColumnClick: (e, column) => onColumnClick(column),
      data: "string",
      onRender: (item) => {
        return (
          <div className="logs__list-cell">
            {moment(item.created).format("YYYY/MM/DD HH:mm")}
          </div>
        );
      },
      isPadded: true,
      isSorted: sortedColumnKey === "created",
      isSortedDescending,
    },
    {
      key: "action",
      name: "Action (Operation Performed)",
      fieldName: "action",
      minWidth: 200,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: labels.get('sorted_az'),
      sortDescendingAriaLabel: labels.get('sorted_za'),
      onColumnClick: (e, column) => onColumnClick(column),
      data: "string",
      onRender: (item) => {
        if (item.message) {
          return (
            <TooltipHost
              id={`messageTooltip-${item.id}`}
              content={item.message}
            >
              <div
                className="list__cell"
                aria-describedby={`messageTooltip-${item.id}`}
              >
                {item.action} - Show details
              </div>
            </TooltipHost>
          );
        }

        return <div className="logs__list-cell">{item.action}</div>;
      },
      isPadded: true,
      isSorted: sortedColumnKey === "action",
      isSortedDescending,
    },
    {
      key: "logType",
      name: labels.get('log_type'),
      fieldName: "logType",
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: labels.get('sorted_az'),
      sortDescendingAriaLabel: labels.get('sorted_za'),
      onColumnClick: (e, column) => onColumnClick(column),
      data: "string",
      onRender: (item) => {
        return <div className="logs__list-cell">{item.logType}</div>;
      },
      isPadded: true,
      isSorted: sortedColumnKey === "logType",
      isSortedDescending,
    },
    {
      key: "object",
      name: labels.get('object'),
      fieldName: "object",
      minWidth: 350,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      onRender: (item) => {
        return (
          <div className="logs__list-cell">
            {item.employee
              ? `${labels.get('employee')} ${
                  item.employee.principalId || item.employee.principalName
                }`
              : ""}
            {item.user
              ? `${labels.get('user')} ${item.user.principalId || item.user.principalName}`
              : ""}
            {item.unit ? `Unit ${item.unit.unitName}` : ""}
            {item.mailbox
              ? `${labels.get('mailbox')} ${
                  item.mailbox.principalId || item.mailbox.principalName
                }`
              : ""}
          </div>
        );
      },
      isPadded: true,
    },
  ];

  const getKey = (item) => {
    return item.id;
  };

  return (
    <div className="logs">
      <h2 className="logs__heading">{type} logs</h2>

      <div className="logs__filters">
        <TextField
          label={labels.get('search_logs')}
          value={searchValue}
          onChange={(_, text) => changeSearchValue(text)}
        />
      </div>
      <div className="logs__list">
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            items={logs}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="id"
            getKey={getKey}
            constrainMode={ConstrainMode.unconstrained}
            layoutMode={
              size.width > 1024
                ? DetailsListLayoutMode.justified
                : DetailsListLayoutMode.fixedColumns
            }
            onRenderRow={(props, defaultRender) =>
              props.item?.status !== "active"
                ? defaultRender({ ...props, className: "list__row--disabled" })
                : defaultRender(props)
            }
            isHeaderVisible={true}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn={labels.get('toggle_selection')}
            ariaLabelForSelectAllCheckbox={labels.get('toggle_selection_for_all_items')}
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              return (
                <Sticky
                  stickyPosition={StickyPositionType.Header}
                  isScrollSynced={true}
                  stickyBackgroundColor="transparent"
                >
                  <div>{defaultRender(headerProps)}</div>
                </Sticky>
              );
            }}
          />
          {!logs || logs.length === 0 ? (
            <div style={{ textAlign: "center" }}>{labels.get('there_are_no_logs')}</div>
          ) : null}
          {logs && logs.length && logs.length % 100 === 0 ? (
            <div className="logs__buttons">
              <PrimaryButton onClick={handleLoadMore}>{labels.get('load_more')}</PrimaryButton>
            </div>
          ) : null}
          {loadingData ? (
            <div className="center">
              <Spinner className="spinner" label={`${labels.get('loading_logs')}...`} />
            </div>
          ) : null}
        </ScrollablePane>
      </div>
    </div>
  );
};

export default Logs;
